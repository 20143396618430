import { Alert, Button, Form, Modal, Radio } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { clearRenewalPaymentPending, setRenewalPaymentPending } from "./api";
import { PHONE_NUMBERS_FOR_UI } from "./constants/phoneNumbers";
import PaddleCheckoutForm from "./PaddleCheckoutForm";
import PaymentPendingScreen from "./PaymentPendingScreen";
import { setMostRecentPaymentDateInLocalStorage } from "./utils/paymentUtils";

const FORM_STATES = {
  CLEARING_PAYMENT_PENDING: "CLEARING_PAYMENT_PENDING",
  SHOW_CHECKOUT: "SHOW_CHECKOUT",
  ERROR_CLEARING_PAYMENT_PENDING: "ERROR_CLEARING_PAYMENT_PENDING",
};

const RenewAccountCheckout = ({ userInfo, fetchUserInfo, onClose }) => {
  const [formState, setFormState] = useState(FORM_STATES.SHOW_CHECKOUT);

  const isPaymentPending =
    userInfo && userInfo.isRenewalPaymentPending && !userInfo.isEnabled;

  const isEnabled = userInfo && userInfo.isEnabled;

  const isEnabledButStillPending =
    userInfo && userInfo.isEnabled && userInfo.isRenewalPaymentPending;

  const isMostRecentPaymentDateLessTwentyMinutesAgo =
    userInfo && userInfo.isMostRecentPaymentDateLessTwentyMinutesAgo;
  const [hasPaidLocally, setHasPaidLocally] = useState(false);

  if (formState === FORM_STATES.SHOW_CHECKOUT) {
    if (isEnabledButStillPending) {
      return (
        <Modal
          title={
            <h3 style={{ margin: 0 }}>Account renewed! Just one more step.</h3>
          }
          visible={true}
          // onCancel={onClose}
          footer={
            <div>
              <Button
                onClick={() => {
                  clearRenewalPaymentPending();
                  onClose();
                }}
                type="primary"
              >
                OK
              </Button>
            </div>
          }
        >
          <h4>1. Text START to {PHONE_NUMBERS_FOR_UI.TELNYX}</h4>
          <p>(If you have previously opted out by texting STOP)</p>
          <h4>
            2. Double check your settings to make sure alerts are turned on and
            update your cutoff date if necessary.
          </h4>
        </Modal>
      );
    } else if (
      isPaymentPending ||
      hasPaidLocally ||
      isMostRecentPaymentDateLessTwentyMinutesAgo
    ) {
      return (
        <Modal
          title={<h3 style={{ margin: 0 }}>Processing renewal order</h3>}
          visible={true}
          // width="90vw"
          // onCancel={onClose}
          footer={null}
          closable={false}
        >
          <PaymentPendingScreen fetchUserInfo={fetchUserInfo} />
        </Modal>
      );
    } else {
      return (
        <PaddleCheckoutForm
          onSuccess={() => {
            setMostRecentPaymentDateInLocalStorage();
            setHasPaidLocally(true);
            setRenewalPaymentPending().then(() => {
              fetchUserInfo();
            });
          }}
          userInfo={userInfo}
          fetchUserInfo={fetchUserInfo}
          setHasPaidLocally={setHasPaidLocally}
          passthrough={{ isRenewing: true }}
        />
      );
    }
  } else if (formState === FORM_STATES.ERROR_CLEARING_PAYMENT_PENDING) {
    return null;
  } else {
    return null;
  }
};

export default RenewAccountCheckout;
