import "antd/dist/antd.css";
// import { user } from "firebase-functions/lib/providers/auth";
// If you4 enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getHubSpotVisitorToken } from "./api";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./Footer";
import LocationsPage from "./LocationsPage";
import PreferencesPageContainer from "./PreferencesPageContainer";
import SignupFlow from "./SignupFlow";
import { isQA } from "./utils/domainUtils";
import { updateQueryStringParameter } from "./utils/queryParamUtils";
import { UserInfoContextProvider } from "./utils/userInfoUtils";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = isQA()
  ? {
      apiKey: "AIzaSyA4ANPkA8sLwhEtKzdldu-vaRuJTArDlz0",
      authDomain: "auth.qa.appointmentscanner.com",
      databaseURL: "https://qa-globalentrysentry.firebaseio.com",
      projectId: "qa-globalentrysentry",
      storageBucket: "qa-globalentrysentry.appspot.com",
      messagingSenderId: "853399809856",
      appId: "1:853399809856:web:cfa2cbb0fdcdb8ed7594a5",
      measurementId: "G-5TWBTGHLT7",
    }
  : {
      apiKey: "AIzaSyCxR5OtwmIxEAUwIyvj08pe9YMrzSK2fPo",
      authDomain: "auth.appointmentscanner.com",
      databaseURL: "https://globalentrysentry.firebaseio.com",
      projectId: "globalentrysentry",
      storageBucket: "globalentrysentry.appspot.com",
      messagingSenderId: "844123184736",
      appId: "1:844123184736:web:2ee28983fd2cc0a6d7ad05",
      measurementId: "G-ZDPNEXFVV3",
    };

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    localStorage.setItem("PREVIOUSLY_SIGNED_IN", true);
  } else {
    localStorage.removeItem("PREVIOUSLY_SIGNED_IN");
  }
});

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    firebase.auth().currentUser
  );

  const setUserIdParam = async () => {
    const userId = firebase.auth().currentUser.uid;
    // if (!window.location.search.includes("oobCode")) {
    //   updateQueryStringParameter("i", userId);
    // }
    // window.HubSpotConversations.widget.load();
    window.$crisp.push(["set", "session:data", [[["userId", userId]]]]);
  };

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setUserIdParam();
    } else {
      // No user is signed in.
    }
  });
  return (
    <Router>
      <UserInfoContextProvider>
        <Navbar />
        <div style={{ minHeight: "calc(100vh - 100px)" }}>
          <Switch>
            <Route path="/signup">
              <SignupFlow />
            </Route>
            <Route path="/dashboard">
              <PreferencesPageContainer />
            </Route>
            <Route path="/locations">
              <LocationsPage />
            </Route>
            <Route path="/">
              <SignupFlow />
            </Route>
          </Switch>
        </div>
        <Footer />
      </UserInfoContextProvider>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}
