import { Alert, Button, Icon } from "antd";
import React from "react";
import GettingStartedGuide from "./GettingStartedGuide";
import TrainingSection from "./TrainingSection";

const DoneStep = () => {
  return (
    <div style={{ width: "100vw", height: "calc(100vh - 100px)" }}>
      <TrainingSection
        onSkip={() => {
          window.location = "/dashboard?ft=true";
        }}
        onComplete={() => {
          window.location = "/dashboard?ft=true";
        }}
      />
    </div>
  );
  return (
    <div style={{ textAlign: "center" }}>
      <Icon
        style={{ fontSize: "50px", color: "#1890ff" }}
        type="check-circle"
      />
      <h2>Your account is all set up!</h2>
      <h3>What happens now?</h3>
      <div style={{ padding: "30px", paddingBottom: 0 }}></div>

      {/* <Alert
        style={{ marginTop: "10px", maxWidth: "500px" }}
        type="success"
        message="Your account is all set up!"
      /> */}

      <Button
        size="large"
        type="primary"
        style={{ marginTop: "30px", marginBottom: "30px" }}
        onClick={() => {
          window.location = "/dashboard?ft=true";
        }}
      >
        Continue to your dashboard
      </Button>
    </div>
  );
};

export default DoneStep;
