import { Button, Input } from "antd";
import * as firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect, useState } from "react";
import { IoMailOutline, IoCheckmarkCircle } from "react-icons/io5";

const STATUSES = {
  INITIAL: "INITIAL",
  VERIFYING: "VERIFYING",
  SUBMITTING: "SUBMITTING",
  LOGIN_PENDING: "LOGIN_PENDING",
  SENT: "SENT",
  SUCCESS: "SUCCESS",
  ERROR_SENDING: "ERROR_SENDING",
  ERROR_VERIFYING: "ERROR_VERIFYING",
};

const MagicLinkSignIn = ({
  showLogin = true,
  status,
  setStatus,
  onSuccess,
}) => {
  const [emailValue, setEmailValue] = useState("");

  const isEmailInvalid =
    !emailValue.length ||
    !emailValue.includes("@") ||
    !emailValue.includes(".");

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: window.location.origin,
    // This must be true.
    handleCodeInApp: true,
    //   iOS: {
    //     bundleId: "com.example.ios"
    //   },
    //   android: {
    //     packageName: "com.example.android",
    //     installApp: true,
    //     minimumVersion: "12"
    //   },
  };

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    if (
      window.location.search.includes("oobCode") &&
      firebase.auth().isSignInWithEmailLink(window.location.href)
    ) {
      setStatus(STATUSES.LOGIN_PENDING);
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          onSuccess();
          // onSuccess();
          window.location = window.location.origin;
        })
        .catch(function (error) {
          console.warn(error);
          setStatus(STATUSES.ERROR_VERIFYING);

          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    firebase.auth().onAuthStateChanged(function (user) {
      if (user && status !== STATUSES.SUCCESS) {
        // setStatus(STATUSES.SUCCESS);
        //   getUserInfo()
        //     .then((res) => res.json())
        //     .then((data) => {
        //       setUserInfo(data);
        //     });
      } else {
        // setStatus(STATUSES.INITIAL);
        // No user is signed in.
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmailInvalid) {
      setStatus(STATUSES.VERIFYING);
    } else {
      setStatus(STATUSES.SUBMITTING);

      firebase
        .auth()
        .sendSignInLinkToEmail(emailValue, actionCodeSettings)
        .then(function () {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", emailValue);
          setStatus(STATUSES.SENT);
        })
        .catch(function (error) {
          console.warn(error);
          setStatus(STATUSES.ERROR_SENDING);

          // Some error occurred, you can inspect the code: error.code
        });
    }
  };

  switch (status) {
    case STATUSES.INITIAL:
    case STATUSES.SUBMITTING:
    case STATUSES.VERIFYING:
      return (
        <div textAlign="center" justifyContent="center" alignItems="center">
          <p>––– or –––</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            {/* <FormControl
              isInvalid={status === STATUSES.VERIFYING && isEmailInvalid}
            > */}
            <div w="100%" style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex", maxWidth: "350px" }}>
                <Input
                  value={emailValue}
                  placeholder="Email address"
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                    e.stopPropagation();
                  }}
                  maxWidth="300px"
                />
                <Button
                  onClick={(e) => handleSubmit(e)}
                  variant="solid"
                  colorScheme="brand"
                  type="submit"
                  // disabled={
                  //   !emailValue.length ||
                  //   !emailValue.includes("@") ||
                  //   !emailValue.includes(".")
                  // }
                  // size="large"
                  isLoading={status === STATUSES.SUBMITTING}
                >
                  {showLogin ? "Sign in" : "Sign up"}
                </Button>{" "}
              </div>
            </div>
            {isEmailInvalid && status === STATUSES.VERIFYING && (
              <p style={{ color: "red" }}>
                Please double check your email address.
              </p>
            )}

            <p>We'll email you a magic link to sign in.</p>
            {/* </FormControl> */}
          </form>
        </div>
      );
    case STATUSES.SENT:
      return (
        <div style={{ textAlign: "center" }}>
          <div justifyContent="center" alignItems="center" direction="column">
            <IoMailOutline size="35px" style={{ marginRight: "5px" }} />
            <p style={{ fontSize: "16pt", fontWeight: 500 }}>
              Thanks, now check your email
            </p>
            <p>We sent a sign in link to {emailValue}.</p>
            <p>Click the link to finish signing in.</p>
          </div>
          {/* <Icon type="mail" theme="twoTone" style={{ fontSize: "32px" }} /> */}
        </div>
      );

    case STATUSES.SUCCESS:
      return (
        <div justifyContent="center">
          <IoCheckmarkCircle size="40px" color="#1CAC78" />
        </div>
      );

    case STATUSES.ERROR_SENDING:
      return (
        <div textAlign="center">
          <h2 size="md">We're having trouble sending your email right now.</h2>
          <p>
            Please double check that it's a valid email address or try a
            different sign in method.
          </p>
          <p>If this issue persists, contact support.</p>
          <Button onClick={() => setStatus(STATUSES.INITIAL)}>Back</Button>
        </div>
      );
    case STATUSES.ERROR_VERIFYING:
      return (
        <p textAlign="center">
          <h2 size="md" mb="10px">
            We're having trouble signing you in.
          </h2>
          <p>
            Please make sure you've clicked the most recent sign in email that
            you've received or reload the page and try again.
          </p>
          <Button onClick={() => setStatus(STATUSES.INITIAL)}>Back</Button>
        </p>
      );
    case STATUSES.LOGIN_PENDING:
      return (
        <p
          w="100%"
          minHeight="300px"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Spinner /> */}
        </p>
      );
    default:
      return null;
  }
};

export default MagicLinkSignIn;
