export const PROGRAM_TYPES = {
  GLOBAL_ENTRY: "GLOBAL_ENTRY",
  SENTRI: "SENTRI",
  NEXUS: "NEXUS",
};

export const programTypeToName = {
  GLOBAL_ENTRY: "Global Entry",
  SENTRI: "SENTRI",
  NEXUS: "NEXUS",
};
