// CardSection.js
import { Alert, Icon, Spin, Steps } from "antd";
import * as firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import { getUserInfo, setPaymentPending } from "./api";
import CheckoutQuestionaireForm from "./CheckoutQuestionaireForm";
import DoneStep from "./DoneStep";
import PreferencesForm from "./PreferencesForm";
import SignUpStep from "./SignUpStep";
import SmsVerifyForm from "./SmsVerifyForm";
import { getIsMostRecentPaymentDateLessTwentyMinutesAgo } from "./utils/paymentUtils";
import {
  LOCATION_MAP_STATUSES,
  UserInfoContext,
  USER_INFO_STATUSES,
} from "./utils/userInfoUtils";
const { Step } = Steps;

const STEPS = {
  INITIAL: "INITIAL",
  LOGIN: 0,
  PAY: 1,
  SET_PREFERENCES: 2,
  VERIFY_SMS: 3,
  DONE: 4,
  DASHBOARD: "DASHBOARD",
};

const SignupFlow = () => {
  const {
    userInfo,
    fetchUserInfo,
    locationMap,
    isAuthenticated,
    isPhoneOptedOut,
  } = useContext(UserInfoContext);

  const [currentStep, setCurrentStep] = useState(STEPS.INITIAL);

  const isLocationMapLoading =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.INITIAL;

  const isLocationMapLoaded =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.LOADED;

  const hasLocationMapErrored =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.ERRORED;

  const hasUserInfoErrored =
    isAuthenticated && userInfo.userInfoStatus === USER_INFO_STATUSES.ERRORED;

  useEffect(() => {
    if (isAuthenticated) {
      console.log("userInfo.isSmsEnabled", userInfo.isSmsEnabled);
      console.log("userInfo.isSmsVerified", userInfo.isSmsVerified);

      if (userInfo.userInfoStatus === USER_INFO_STATUSES.INITIAL) {
        return;
      }

      if (
        userInfo.phone &&
        userInfo.version &&
        userInfo.version >= 2 &&
        !userInfo.isSmsVerified
      ) {
        setCurrentStep(STEPS.VERIFY_SMS);
      } else if (userInfo.enrollmentCenters?.length) {
        window.location = "/dashboard";
      } else if (userInfo.paymentDate) {
        setCurrentStep(STEPS.SET_PREFERENCES);
      } else {
        setCurrentStep(STEPS.PAY);
      }
    } else {
      const isAwaitingLoginRefresh =
        !isAuthenticated && localStorage.getItem("PREVIOUSLY_SIGNED_IN");

      if (!isAwaitingLoginRefresh) {
        setCurrentStep(STEPS.LOGIN);
      }
    }
  }, [userInfo, isAuthenticated]);

  const handleNextClick = (options) => {
    setCurrentStep(currentStep + 1);
    if (currentStep === STEPS.SET_PREFERENCES && !options.hasEnteredPhone) {
      setCurrentStep(currentStep + 2);
    }
  };

  // const handlePreviousClick = () => {
  //   setCurrentStep(currentStep - 1);
  // };

  // const handleSignOutClick = () => {
  //   firebase.auth().signOut();
  //   window.location.reload();
  // };

  if (currentStep === STEPS.INITIAL || isLocationMapLoading) {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          direction: "column",
          padding: "20px",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  if (hasLocationMapErrored || hasUserInfoErrored) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          direction: "column",
          marginTop: "100px",
        }}
      >
        <Alert
          type="error"
          message="We're having trouble loading this page :("
          description="Please refresh the page and try again. If this issue persists, contact support."
          showIcon={true}
        ></Alert>
      </div>
    );
  }

  let steps = [
    {
      title: "Login",
      content: <SignUpStep onSuccess={handleNextClick} />,
      icon: <Icon type="user" />,
    },
    {
      title: "Checkout",
      content: (
        <CheckoutQuestionaireForm
          onSuccess={handleNextClick}
          userInfo={userInfo}
          fetchUserInfo={fetchUserInfo}
        />
      ),
      icon: <Icon type="dollar" />,
    },
    {
      title: "Preferences",
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon
            style={{ fontSize: "50px", color: "#1890ff" }}
            type="check-circle"
          />
          <h2>Order placed successfully. Thanks!</h2>
          <h3 style={{ marginBottom: "20px" }}>
            Now let's get you set up to receive alerts.
          </h3>
          <PreferencesForm
            onSuccess={handleNextClick}
            userInfo={userInfo}
            locationMap={locationMap}
          />
        </div>
      ),
      icon: <Icon type="solution" />,
    },
    {
      title: "Verify",
      content: (
        <div style={{ textAlign: "center" }}>
          <SmsVerifyForm
            onSuccess={handleNextClick}
            userInfo={userInfo}
            fetchUserInfo={fetchUserInfo}
            isPhoneOptedOut={isPhoneOptedOut}
          />
        </div>
      ),
      icon: <Icon type="solution" />,
    },
    {
      title: "Done",
      content: <DoneStep />,
      icon: <Icon type="smile-o" />,
    },
  ];

  const vw = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <div style={{ maxWidth: "100vw" }}>
        {currentStep !== STEPS.DONE && (
          <div
            style={{
              marginTop: "50px",
            }}
          >
            <Steps
              current={currentStep}
              style={{ ...(vw > 900 && { width: "600px" }) }}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </div>
        )}

        <div
          style={{
            marginTop: currentStep === STEPS.DONE ? 0 : "50px",
          }}
        >
          {steps[currentStep].content}
        </div>
        {/* <div className="steps-action">
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={handleNextClick}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary">Done</Button>
          )}
          {currentStep > 0 && (
            <Fragment>
              <Button style={{ marginLeft: 8 }} onClick={handlePreviousClick}>
                Previous
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={handleSignOutClick}>
                Sign out
              </Button>
            </Fragment>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default SignupFlow;
