import { Alert, Button, Collapse, Icon } from "antd";
import React, { Fragment } from "react";
const { Panel } = Collapse;

const GettingStartedGuide = () => {
  return (
    <div style={{ textAlign: "left", fontSize: "14pt" }}>
      <p>
        Appointment Scanner is already hard at work in the background, looking
        for last minute appointments that fit your requirements.
      </p>
      <p>
        When an appointment is found, you'll receive an instant text message
        and/or email alert with the information. To schedule the appointment,{" "}
        <strong>
          you'll need to be quick! Most appoinments are taken within a few
          minutes
        </strong>{" "}
        after they open up.
      </p>
      <ol>
        <li>
          As soon as you see an alert, click the link to visit the Trusted
          Traveler Program (TTP) website.
        </li>
        <li>
          Log in to your Trusted Traveler account and you will be taken to the
          TTP dashboard where you can see your pending applications.
        </li>
        <li>
          Click "Schedule interview" or "Reschedule interview." Do not use the
          "Cancel interview" option
        </li>
        <li>Navigate to your desired enrollment center</li>
        <li>Choose the appointment on the calendar and schedule it</li>
      </ol>
      <p>
        If the TTP scheduler says "No appointments available for this location",
        or a later date than expected, this means someone else has claimed the
        appointment first. It's common to have to try a few times to claim an
        appointment first. The accounts last for 1 month to give you plenty of
        chances to book an appointment.
      </p>
      <h2>FAQ</h2>
      <Collapse
        defaultActiveKey={["1"]}
        onChange={() => {}}
        style={{ textAlign: "left", fontSize: "14pt" }}
      >
        <Panel
          header="How do I turn off alerts after finding an appointment?"
          key="1"
          defaultActiveKey
        >
          <p>
            Just text STOP, or click the unsubscribe link in the bottom of any
            email alert. Either option will turn off all future alerts–both
            email and text message. You can also visit your dashboard at
            https://app.appointmentscanner.com/dashboard. Toggle off alerts and
            click save.
          </p>
        </Panel>
        <Panel
          header="Is this a subscription? Will you charge me again after the month?"
          key="2"
        >
          <p>
            No. Appointment Scanner is sold as a one-time payment for 1 month of
            alerts. After the month, your account will auto expire and will
            never charge you again. If you need more time after your account
            expires, you can purchase an additional month of alerts by logging
            into your Appointment Scanner dashboard.
          </p>
        </Panel>
        <Panel header="What if I need more time than 1 month?" key="3">
          <p>
            If you need more time after your account expires, you can purchase
            an additional month of alerts by logging into your Appointment
            Scanner dashboard.
          </p>
        </Panel>
        <Panel header="What's the refund policy?" key="4">
          <p>
            If for any reason you're not satisfied with Appointment Scanner,
            contact us within 30 days of purchasing to request a refund. That's
            it!
          </p>
        </Panel>
      </Collapse>
    </div>
  );
};

export default GettingStartedGuide;
