import { Button, Modal, Radio } from "antd";
import React from "react";
import moment from "moment";

import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

const isChrome = browser.getBrowserName() === "Chrome";
const isEdge = browser.getBrowserName() === "Edge";
const isSafari = browser.getBrowserName() === "Safari";
const isFirefox = browser.getBrowserName() === "Firefox";

//TODO: there is actually way more complexity here in different browsers and OS's. This may need to be done more precisely in the future if users have trouble
const BrowserAlertsTipsModal = ({ onClose }) => {
  return (
    <Modal
      title={<h3 style={{ margin: 0 }}>How to allow browser alerts</h3>}
      visible={true}
      // closable={true}
      onCancel={onClose}
      footer={null}
    >
      {isChrome && (
        <>
          <ol>
            <li>Click the lock icon in the address bar</li>{" "}
            <li>Switch notifications on</li>
          </ol>
          <img
            src={"chrome-notifications.jpg"}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </>
      )}
      {/* TODO: this one in particular needs work. Differs the first time you do it and differs on windows*/}
      {isFirefox && (
        <>
          <ol>
            <li>Click the notification icon in the address bar</li>{" "}
            <li>Switch notifications to "Allowed"</li>
          </ol>
          <img
            src={"firefox-notifications.jpg"}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </>
      )}

      {isEdge && (
        <>
          <ol>
            <li>Click the lock icon in the address bar</li>{" "}
            <li>Switch notifications on</li>
          </ol>
          <img
            src={"edge-notifications.jpg"}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </>
      )}
      {isSafari && (
        <>
          <ol>
            <li>In the Mac top bar, click "Safari" and then "Preferences"</li>{" "}
            <img
              src={"safari-notifications-1.jpg"}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
            <li>Click the "Websites" tab</li>
            <li>Click "Notifications" in the left sidebar</li>
            <li>Allow app.appointmentscanner.com to send notifications</li>
          </ol>

          <img
            src={"safari-notifications-2.jpg"}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </>
      )}
    </Modal>
  );
};

export default BrowserAlertsTipsModal;
