import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { getLocationMap, getUserInfo, getPhoneOptInState } from "../api";
import { getIsMostRecentPaymentDateLessTwentyMinutesAgo } from "./paymentUtils";
import * as firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";

export const USER_INFO_STATUSES = {
  INITIAL: "INITIAL",
  LOADED_HAS_NO_ACCOUNT: "LOADED_HAS_NO_ACCOUNT",
  LOADED_HAS_ACCOUNT: "LOADED_HAS_ACCOUNT",
  ERRORED: "ERRORED",
};

export const LOCATION_MAP_STATUSES = {
  INITIAL: "INITIAL",
  LOADED: "LOADED",
  ERRORED: "ERRORED",
};

const sortLocationMap = (locationMap) => {
  const sortedLocationMapList = {
    IN_PERSON: {
      GLOBAL_ENTRY: {},
      SENTRI: {},
      NEXUS: {},
    },
    REMOTE: {
      GLOBAL_ENTRY: {},
      SENTRI: {},
      NEXUS: {},
    },
  };
  const transformToListAndSort = (object) =>
    Object.entries(object)
      .map(([id, locationData]) => ({
        id,
        name: locationData.state + " - " + locationData.name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

  sortedLocationMapList.IN_PERSON.GLOBAL_ENTRY = transformToListAndSort(
    locationMap.IN_PERSON.GLOBAL_ENTRY
  );

  sortedLocationMapList.IN_PERSON.SENTRI = transformToListAndSort(
    locationMap.IN_PERSON.SENTRI
  );
  sortedLocationMapList.IN_PERSON.NEXUS = transformToListAndSort(
    locationMap.IN_PERSON.NEXUS
  );

  sortedLocationMapList.REMOTE.GLOBAL_ENTRY = transformToListAndSort(
    locationMap.REMOTE.GLOBAL_ENTRY
  );

  return sortedLocationMapList;
};

export const UserInfoContext = createContext();

export const UserInfoContextProvider = ({ children }) => {
  const [isPhoneOptedOut, setIsPhoneOptedOut] = useState();
  const [userInfo, setUserInfo] = useState({
    isMostRecentPaymentDateLessTwentyMinutesAgo:
      getIsMostRecentPaymentDateLessTwentyMinutesAgo(),
    userInfoStatus: USER_INFO_STATUSES.INITIAL,
  });

  const [locationMap, setLocationMap] = useState({
    metadata: { status: LOCATION_MAP_STATUSES.INITIAL },
  });

  const [isAuthenticated, setIsAuthenticated] = useState(
    firebase.auth().currentUser
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user && !isAuthenticated) {
        setIsAuthenticated(true);
        fetchUserInfo();
      } else {
        // No user is signed in.
      }
    });
    return unsubscribe;
  });

  useEffect(() => {
    getLocationMap()
      .then((data) => {
        setLocationMap({
          metadata: { status: LOCATION_MAP_STATUSES.LOADED },
          ...data,
          sortedLists: sortLocationMap(data),
        });
      })
      .catch((err) => {
        console.log(err);
        setLocationMap({ metadata: { status: LOCATION_MAP_STATUSES.ERRORED } });
      });
  }, []);

  const fetchUserInfo = () => {
    getUserInfo()
      .then((data) => {
        const emailFromAuth = firebase.auth().currentUser.email;

        if (window.$crisp && data.crispEmailSignature) {
          window.$crisp.push([
            "set",
            "user:email",
            [emailFromAuth, data.crispEmailSignature],
          ]);
        }

        console.log("got data");
        const isMostRecentPaymentDateLessTwentyMinutesAgo =
          getIsMostRecentPaymentDateLessTwentyMinutesAgo();
        console.log(
          "isMostRecentPaymentDateLessTwentyMinutesAgo",
          isMostRecentPaymentDateLessTwentyMinutesAgo
        );

        // get opt in status of current user's `phone` from Firestore
        // must be in 8 digit format with `1` US, Canada country code prefix
        // i.e. `19175551234`
        getPhoneOptInState()
          .then((blocklistData) => {
            setIsPhoneOptedOut(blocklistData.isOptedOut);
          })
          .catch((error) => {
            console.log(error);
          });

        setUserInfo({
          ...data,
          isMostRecentPaymentDateLessTwentyMinutesAgo,
          userInfoStatus: USER_INFO_STATUSES.LOADED_HAS_ACCOUNT,
        });
      })

      .catch((e) => {
        const isMostRecentPaymentDateLessTwentyMinutesAgo =
          getIsMostRecentPaymentDateLessTwentyMinutesAgo();
        console.log(
          "isMostRecentPaymentDateLessTwentyMinutesAgo",
          isMostRecentPaymentDateLessTwentyMinutesAgo
        );

        if (e.message.includes("404")) {
          setUserInfo({
            isMostRecentPaymentDateLessTwentyMinutesAgo,
            userInfoStatus: USER_INFO_STATUSES.LOADED_HAS_NO_ACCOUNT,
          });
        } else {
          console.log("user info errored", e);
          setUserInfo({
            isMostRecentPaymentDateLessTwentyMinutesAgo,
            userInfoStatus: USER_INFO_STATUSES.ERRORED,
          });
        }
      });
  };

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        fetchUserInfo,
        locationMap,
        isAuthenticated,
        isPhoneOptedOut,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
