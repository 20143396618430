export const REMOTE_LOCATION_ID = 999999999;

export const getNameForLocationId = (locationId, locationMap) => {
  if (String(locationId) === String(REMOTE_LOCATION_ID)) {
    return "Remote / Zoom (renewals only)";
  }

  return (
    locationMap.IN_PERSON.GLOBAL_ENTRY[locationId]?.name ||
    locationMap.IN_PERSON.NEXUS[locationId]?.name ||
    locationMap.IN_PERSON.SENTRI[locationId]?.name ||
    locationMap.REMOTE.GLOBAL_ENTRY[locationId]?.name ||
    locationMap.REMOTE.NEXUS[locationId]?.name ||
    locationMap.REMOTE.SENTRI[locationId]?.name ||
    "Unknown Center"
  );
};
