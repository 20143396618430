import { Button, Modal, Input, Spin, Icon, Form } from "antd";
import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { getMissingCenters } from "./api";

const exampleCenters = [
  {
    name: "CFO - All About Travel",
    locationType: "TEMPORARY",
    address: "3801 S Western Avenue",
    city: "Sioux Falls",
    state: "SD",
    postalCode: "57105",
    program: "GLOBAL_ENTRY",
  },
];

const PROGRAM = {
  GLOBAL_ENTRY: "GLOBAL_ENTRY",
  SENTRI: "SENTRI",
  NEXUS: "NEXUS",
};

// function to open official listing page URL in new tab, for each program
// input should match `PROGRAM` type above, replace underscore with dash for GE
const openOfficialListingPage = (program) => {
  let pgrm = program;
  if (program === PROGRAM.GLOBAL_ENTRY) {
    pgrm = program.replace(/_/g, "-");
  }
  const url = `https://www.cbp.gov/travel/trusted-traveler-programs/${pgrm}/enrollment-centers`;
  window.open(url, "_blank").focus();
};

const genericMissingCenterDescription =
  "This is a past temporary enrollment event, not a permanent enrollment center. There are no current openings at this location for us to track.";

// expandable description component (not used anymore)
const Description = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggle = () => setIsExpanded(!isExpanded);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {isExpanded && <p>{text}</p>}
      <Button type="link" onClick={toggle}>
        {isExpanded ? "Hide" : "Read more"}
      </Button>
    </div>
  );
};

const CenterTypeBadge = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "#fcba03",
        borderRadius: "4px",
        padding: "2px",
        fontWeight: "700",
        fontSize: "10px",
        width: "120px",
        height: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "8px",
      }}
    >
      {text}
    </div>
  );
};

const MissingCenter = ({
  name,
  locationType,
  description,
  city,
  state,
  id,
  setSelectedCenter,
}) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "12px",
        backgroundColor: "#F7F7F7",
        border: "1px solid #C2C2C2",
        borderRadius: "4px",
        margin: "16px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 style={{ fontSize: "16px", marginBottom: "4px" }}>{name}</h2>
          <CenterTypeBadge
            text={
              locationType === "TEMPORARY" ? "TEMPORARY EVENT" : locationType
            }
          />
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            onClick={() =>
              setSelectedCenter({
                id,
                city,
                state,
                description,
                locationType,
                name,
              })
            }
            style={{ margin: "auto 0" }}
          >
            Learn more
            <Icon type="arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const MissingCenterModal = ({ onClose }) => {
  const [missingCenters, setMissingCenters] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetchingCenters, setisFetchingCenters] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    setisFetchingCenters(true);
    getMissingCenters()
      .then((data) => {
        setMissingCenters(data);
        setisFetchingCenters(false);
      })
      .catch((err) => {
        console.log(err);
        setisFetchingCenters(false);
      });
  }, []);

  return (
    <Modal
      bodyStyle={{ height: "600px" }}
      title={<h3 style={{ margin: 0 }}>Missing an enrollment center?</h3>}
      visible={true}
      onCancel={() => {
        onClose();
      }}
      footer={
        <div>
          <Button
            onClick={() => {
              onClose();
            }}
            type="primary"
          >
            Close
          </Button>
        </div>
      }
    >
      {selectedCenter === null && (
        <div>
          <h4>Search for missing centers to learn more</h4>

          <Input
            autoFocus={true}
            placeholder="Search by center name, city, or state"
            autoComplete="none"
            data-lpignore="true"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ margin: "24px 0" }}
            // addonAfter={<MdSearch style={{ marginTop: "2px" }} />}
          />
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            {isFetchingCenters && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spin indicator={antIcon} />
              </div>
            )}
            {missingCenters &&
              isFetchingCenters === false &&
              missingCenters
                .filter((c) => {
                  return (
                    c.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase().trim()) ||
                    c.city
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase().trim()) ||
                    c.state
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase().trim())
                  );
                })
                .sort((a, b) => a.state.localeCompare(b.state))
                .map((c) => {
                  return (
                    <MissingCenter
                      key={`${c.name} + ${Math.floor(
                        100000 + Math.random() * 900000
                      )}`}
                      name={
                        <span>
                          {`${c.state && c.state + " - "} ${c.city} - ${
                            c.name
                          }`}
                        </span>
                      }
                      locationType={c.locationType}
                      description={c.description ?? null}
                      city={c.city ?? ""}
                      state={c.state ?? ""}
                      setSelectedCenter={setSelectedCenter}
                      id={c.id}
                    />
                  );
                })}
            {!isFetchingCenters && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    height: "100%",
                    paddingTop: "24px",
                    color: "black",
                  }}
                >
                  Still can't find the enrollment center you're looking for? We
                  scan daily for new enrollment centers, but it’s possible
                  you’ve found a new center we haven’t added yet.
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => window.$crisp.push(["do", "chat:open"])}
                    style={{ marginBottom: "14px" }}
                  >
                    Start a chat with our support team
                  </Button>
                  <Button
                    target="_blank"
                    href="mailto:support@appointmentscanner.com"
                  >
                    Email support
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {selectedCenter !== null && (
        <div style={{ maxHeight: "550px", overflowY: "scroll" }}>
          <Button onClick={() => setSelectedCenter(null)}>
            <Icon type="arrow-left" />
            Back
          </Button>
          <p
            style={{
              marginTop: "8px",
              marginBottom: "0",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {selectedCenter.name}
          </p>
          <p
            style={{ marginBottom: "4px", fontSize: "14px", fontWeight: "500" }}
          >
            {selectedCenter.city}
            {selectedCenter.state && <span>, {selectedCenter.state}</span>}
          </p>
          <CenterTypeBadge
            text={
              selectedCenter.locationType === "TEMPORARY"
                ? "TEMPORARY EVENT"
                : selectedCenter.locationType
            }
          />

          <p style={{ fontWeight: "700", fontSize: "16px", marginTop: "10px" }}>
            About this event
          </p>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            {selectedCenter?.description ?? genericMissingCenterDescription}
          </p>
          <p style={{ fontWeight: "700", fontSize: "16px" }}>
            What are temporary enrollment events?
          </p>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            There are two types of locations where you can complete your
            interview.{" "}
            <p>
              <strong>Permanent enrollment centers</strong> are open all year
              round. All permanent centers are available for alerting in
              Appointment Scanner.
            </p>
            <p>
              <strong>Temporary enrollment events</strong> (also called "mobile
              events") are hosted at airports and convention centers
              periodically. They can last anywhere from 1 day to 1 month.
            </p>
          </p>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            Unfortunately, the Trusted Traveler Program website does not make it
            clear which centers are permanent and which are temporary events.
          </p>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            Whenever possible, Appointment Scanner will make temporary events
            available for monitoring. Sometimes however, temporary events are
            not available for alerting in Appointment Scanner, for a few
            reasons:
          </p>
          <ol>
            <li>
              Almost all of the temporary events on the TTP website are already
              long passed. The CBP often fails to remove the events from the
              site after they are done.
            </li>
            <li>
              Many of the temporary events are invite-only, for attendees of
              certain industry conferences and trade shows.
            </li>
            <li>
              Because some of them are so short, they usually don't produce
              enough last-minute cancellations to be useful in Appointment
              Scanner.
            </li>
          </ol>

          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            You can view the list of all the official permanent enrollment
            centers{" "}
            <a
              target="_blank"
              href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-centers"
            >
              on the CBP website
            </a>
            .
          </p>
          <p
            style={{
              height: "100%",
              paddingTop: "24px",
              color: "black",
            }}
          >
            Still need help?
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Button
              onClick={() => window.$crisp.push(["do", "chat:open"])}
              style={{ marginBottom: "14px" }}
            >
              Start a chat with our support team
            </Button>
            <Button
              target="_blank"
              href="mailto:support@appointmentscanner.com"
            >
              Email support
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MissingCenterModal;
