import React, { useEffect, useState } from "react";
import { programTypeToName } from "./constants/programTypes";
import * as firebase from "firebase";
import Push from "push.js";
import difference from "lodash.difference";
import { Alert, Button, Icon, Spin, Switch } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoInformationCircle,
  IoInformationCircleOutline,
  IoWarning,
} from "react-icons/io5";
import SpeedBadge from "./SpeedBadge";
import { getNameForLocationId } from "./utils/locationUtils";
import moment from "moment";
import { handleSubmitRef } from "./utils/preferenceUtils";
import BrowserAlertsTipsModal from "./BrowserAlertsTipsModal";
import Bowser from "bowser";
import {
  getDayNameFromNumber,
  getNonExcludedDayNumbersFromExcludedDays,
} from "./utils/dateUtils";

const browser = Bowser.getParser(window.navigator.userAgent);

const isDesktopBrowser = browser.getPlatformType() === "desktop";

const getTimeStampFromSlotId = (slotId) =>
  slotId.substring(slotId.indexOf("-") + 1, slotId.length);

const localSlotsSeen = {};

const getValidDateSlotIds = (slotIds, currentInterviewDate, excludedDays) => {
  return slotIds.filter((slotId) => {
    const isSlotBeforeCutoffDate = moment(
      getTimeStampFromSlotId(slotId)
    ).isBefore(currentInterviewDate, "day");

    const startTimestamp = getTimeStampFromSlotId(slotId);

    const dayNumber = moment(startTimestamp).day();
    const isSlotOnExcludedDay = excludedDays.includes(dayNumber);
    return isSlotBeforeCutoffDate && !isSlotOnExcludedDay;
  });
};

const BrowserAlertsSection = ({ userInfo, locationMap, fetchUserInfo }) => {
  const db = firebase.firestore();

  const nonExcludedDays =
    userInfo.excludedDays?.length &&
    getNonExcludedDayNumbersFromExcludedDays(userInfo.excludedDays);

  const enrollmentCenters = userInfo.enrollmentCenters;
  const isEnabled = userInfo.isEnabled;

  const [currentRenderId, setCurrentRenderId] = useState(null);
  const [showLoadingIndicators, setShowLoadingIndicators] = useState(false);
  const [isBrowserAlertsEnabledLocally, setIsBrowserAlertsEnabledLocally] =
    useState(userInfo.isBrowserAlertsEnabled);
  const isBrowserAlertsEnabled = userInfo.isBrowserAlertsEnabled;
  const [showTipsModal, setShowTipsModal] = useState(false);

  const hasNotificationPermissions = Push.Permission.has();

  useEffect(() => {
    setIsBrowserAlertsEnabledLocally(isBrowserAlertsEnabled);
  }, [isBrowserAlertsEnabled]);

  const reRender = () => {
    setCurrentRenderId(uuidv4());
  };

  useEffect(() => {
    if (!hasNotificationPermissions && isBrowserAlertsEnabledLocally) {
      Push.Permission.request(
        () => {
          reRender();
        },
        () => {
          reRender();
        }
      );
    }
  }, [hasNotificationPermissions, isBrowserAlertsEnabledLocally]);

  useEffect(() => {
    var liveAppointmentsRef = db.collection("live-appointments");

    const unsubscribeHandlers = enrollmentCenters.map((locationId) => {
      const unsubscribeHandler = liveAppointmentsRef.doc(locationId).onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (doc) => {
          const locationData = doc.data();

          const newSlots = locationData?.slots || [];

          const validDateNewSlotIds = getValidDateSlotIds(
            newSlots,
            userInfo.currentInterviewDate,
            userInfo.excludedDays
          );

          const newlyAddedSlots = difference(
            validDateNewSlotIds,
            localSlotsSeen[locationId]
          );
          // alert("change");
          console.log("newSlots", newSlots);
          console.log("localSlotsSeen", localSlotsSeen);

          console.log("newlyAddedSlots", newlyAddedSlots);

          const locationName = getNameForLocationId(locationId, locationMap);

          const isFirstRun = !localSlotsSeen[locationId];

          if (isFirstRun) {
            localSlotsSeen[locationId] = [];
          }

          const areThereNewSlots = newlyAddedSlots?.length;

          console.log(
            "getTimeStampFromSlotId",
            newlyAddedSlots[0] && getTimeStampFromSlotId(newlyAddedSlots[0])
          );

          if (areThereNewSlots && !isFirstRun && isEnabled) {
            const newlyAddedSlotTimes = newlyAddedSlots.map((slotId) =>
              moment(getTimeStampFromSlotId(slotId)).format("LLLL")
            );

            if (isBrowserAlertsEnabledLocally) {
              Push.create(
                `${newlyAddedSlotTimes.length} New ${
                  programTypeToName[userInfo.program]
                } appointment` + (newlyAddedSlotTimes.length > 1 ? "s" : ""),
                {
                  body:
                    (locationName ? locationName : "") + newlyAddedSlotTimes,
                  icon: "/icon.png",
                  requireInteraction: true,
                  silent: false,
                  vibrate: true,
                  // timeout: 4000,
                  onClick: function () {
                    window.focus();
                    this.close();
                    window.open("https://ttp.dhs.gov", "_blank");
                  },
                }
              );
            }
          }
          localSlotsSeen[locationId] = validDateNewSlotIds;
          reRender();
          console.log("after", localSlotsSeen);
        }
      );
      return unsubscribeHandler;
    });
    return () => {
      unsubscribeHandlers.map((handler) => {
        handler();
      });
    };
  }, [isBrowserAlertsEnabledLocally]);
  return (
    <>
      <div style={{ maxWidth: "600px", textAlign: "left", marginTop: "50px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ flexShrink: 0 }}>Live appointment openings</h2>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            direction: "column",
            padding: "20px 0",
          }}
        >
          <div>
            <Spin
              indicator={
                <Icon
                  type="loading"
                  style={{ fontSize: 20, color: "#32de84", marginRight: "5px" }}
                  spin
                />
              }
            />
            Scanning for appointments before{" "}
            <a href="#cutoff">
              {moment(userInfo.currentInterviewDate).format("LL")}
            </a>
            {nonExcludedDays.length < 7 ? (
              <>
                {" "}
                on{" "}
                <a href="#cutoff">
                  {nonExcludedDays.map(
                    (dayNumber, i) =>
                      getDayNameFromNumber(dayNumber) +
                      (i < nonExcludedDays.length - 1 ? ", " : "")
                  )}
                </a>
              </>
            ) : (
              <>
                {" "}
                on <a href="#cutoff">any day of the week</a>
              </>
            )}
          </div>
          <Button size="small" type="ghost" href="#cutoff">
            Edit
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            direction: "column",
          }}
        >
          <strong>
            <p>Center</p>
          </strong>
          <strong>
            <p>Current matching openings</p>
          </strong>
        </div>
        {enrollmentCenters.map((locationId) => (
          <>
            <div
              style={{
                padding: "10px",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                // height: "100px",
                alignItems: "center",
                border: "1px lightgray solid",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {!localSlotsSeen[locationId] ? (
                  <Icon
                    type="loading"
                    style={{ fontSize: 24, color: "blue", marginRight: "5px" }}
                    spin
                  />
                ) : null}

                {localSlotsSeen[locationId] &&
                !localSlotsSeen[locationId].length ? (
                  <IoCloseCircle color="red" size="25px" />
                ) : null}

                {localSlotsSeen[locationId]?.length ? (
                  <IoCheckmarkCircle color="#32de84" size="25px" />
                ) : null}
                <div style={{ marginLeft: "7px" }}>
                  {getNameForLocationId(locationId, locationMap)}
                </div>
              </div>
              <div>
                {localSlotsSeen[locationId]
                  ? localSlotsSeen[locationId].length
                  : null}
              </div>
            </div>
          </>
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <Button
              size="small"
              type="ghost"
              href="#centers"
              style={{ marginTop: "10px" }}
            >
              Edit centers
            </Button>
          </div>
          <div>
            <Button
              href="https://ttp.dhs.gov"
              target="_blank"
              style={{ marginTop: "20px" }}
            >
              Visit {programTypeToName[userInfo.program]} Site
            </Button>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "40px" }}>
          <Switch
            disabled={!isDesktopBrowser}
            checked={isBrowserAlertsEnabledLocally}
            onChange={(checked) => {
              setIsBrowserAlertsEnabledLocally(checked);
              if (handleSubmitRef.current) {
                handleSubmitRef.current(null, {
                  isBrowserAlertsEnabled: checked,
                });
              }
            }}
          />
          <h3
            style={{
              marginLeft: "10px", //backgroundColor: "#32de84"
            }}
          >
            Browser alerts (unlimited)
          </h3>

          <SpeedBadge
            numBolts={3}
            style={{
              marginLeft: "10px", //backgroundColor: "#32de84"
            }}
          >
            Fastest
          </SpeedBadge>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", maxWidth: "600px" }}>
        {/* <IoInformationCircle color="lightblue" size="30px" /> */}
        <p>
          Keep this dashboard open while you're at your computer to get
          unlimited browser alerts on new openings. While you're away, text
          message and email alerts have you covered.
        </p>
      </div>
      {!isDesktopBrowser && (
        <Alert
          style={{ marginTop: "10px", width: "100%", marginBottom: "10px" }}
          type="warning"
          message="Open this page on a computer to use browser alerts"
          description="Browser notifications are not supported on phones or tablets"
        />
      )}

      {!hasNotificationPermissions &&
        isBrowserAlertsEnabledLocally &&
        isDesktopBrowser && (
          <Alert
            style={{
              marginTop: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
            type="warning"
            message={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {" "}
                  <IoWarning size="20" color="black" />
                  <p style={{ margin: 0, padding: 0 }}>
                    <strong>
                      Grant permission to send browser notifications{" "}
                    </strong>
                  </p>
                </div>
                <Button
                  onClick={() => {
                    setShowTipsModal(true);
                  }}
                >
                  Show me how
                </Button>
              </div>
            }
            // description={<></>}
          />
        )}

      {hasNotificationPermissions && isDesktopBrowser && (
        <Button
          size="xs"
          onClick={() => {
            Push.create(
              `EXAMPLE: New ${programTypeToName[userInfo.program]} appointment`,
              {
                body: `Example Airport at Example Time on Example Date. Click to visit ${
                  programTypeToName[userInfo.program]
                } site.`,
                icon: "/icon.png",
                timeout: 4000,
                onClick: function () {
                  window.focus();
                  this.close();
                  window.open("https://ttp.dhs.gov", "_blank");
                },
              }
            );
          }}
        >
          Show me an example browser alert
        </Button>
      )}

      {showTipsModal && (
        <BrowserAlertsTipsModal
          onClose={() => {
            setShowTipsModal(false);
          }}
        />
      )}
    </>
  );
};

export default BrowserAlertsSection;
