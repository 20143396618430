import React, { useCallback, useState, useEffect, Children } from "react";
import { injectStripe } from "react-stripe-elements";
import { useDropzone } from "react-dropzone";
import request from "superagent";
import {
  Button,
  Input,
  Card,
  Checkbox,
  DatePicker,
  Select,
  Tooltip,
  Switch,
  message,
  Icon,
  Form,
  Alert,
  Radio,
  Badge,
} from "antd";
// import AddressSection from "./AddressSection";
import { Elements } from "react-stripe-elements";
import { placeOrder, setPreferences, getUserInfo } from "./api";
import * as firebase from "firebase/app";
import moment from "moment";
import {
  AsYouType,
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
} from "libphonenumber-js";
import { difference } from "underscore";
import ExcludedDays from "./ExcludedDays";
import { PROGRAM_TYPES } from "./constants/programTypes";
import CurrentInterviewDateWarningModal from "./CurrentInterviewDateWarningModal";
import MissingCenterModal from "./MissingCenterModal";
import { formatPhoneNumber } from "./utils/phoneUtils";
import { MdAccessibleForward, MdBolt, MdErrorOutline } from "react-icons/md";
import SpeedBadge from "./SpeedBadge";
import { REMOTE_LOCATION_ID } from "./utils/locationUtils";
import { selfReportedReferralSourceNameMap } from "./constants/selfReportedReferralSources";
import { handleSubmitRef } from "./utils/preferenceUtils";
import { getSelectedProgramDuringQuestionnaire } from "./utils/programSelectionUtils";
import OptOutFeedbackModal from "./OptOutFeedbackModal";
import { PHONE_NUMBERS_FOR_UI } from "./constants/phoneNumbers";

const { Option } = Select;

const PreferencesForm = ({
  onSuccess,
  userInfo,
  isDashboard,
  fetchUserInfo,
  isDisabled,
  locationMap,
  isPhoneOptedOut,
}) => {
  const PAGE_STATES = {
    INITIAL: "INITIAL",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
  };

  const alertsSentArray = Object.entries(userInfo.alertsSent || {}).sort(
    ([timestampA], [timestampB]) => timestampA.localeCompare(timestampB)
  );

  const numAlertsOnMostRecentDay =
    (alertsSentArray?.length &&
      alertsSentArray[alertsSentArray.length - 1])[1] || 0;

  const defaultUserInfo = {
    isSmsEnabled: false,
    isEmailEnabled: false,
    isEnabled: true,
    isOptedInToMarketingEmails: false,
    phone: null,
    currentInterviewDate: null,
    enrollmentCenters: [],
    expirationDate: null,
    paymentDate: null,
    slotsSeen: [],
    excludedDays: [],
    program:
      getSelectedProgramDuringQuestionnaire() || PROGRAM_TYPES.GLOBAL_ENTRY,
  };
  const getFormDataFromUserInfo = (userInfo) => ({
    ...defaultUserInfo,
    ...userInfo,
    ...(!userInfo.currentInterviewDate && {
      currentInterviewDate: moment().add(12, "months"),
    }),
    ...(userInfo.phone && {
      phone: userInfo.phone.replace("+1", ""),
    }),
    ...(!isDashboard && { isEmailEnabled: true, isSmsEnabled: true }),
  });

  const [formData, setFormData] = useState(getFormDataFromUserInfo(userInfo));

  let locationsList;

  if (formData.program === PROGRAM_TYPES.GLOBAL_ENTRY) {
    locationsList = [
      {
        id: REMOTE_LOCATION_ID,
        name: "Remote / Zoom (eligible renewal applicants only)",
      },
      ...locationMap.sortedLists.IN_PERSON[formData.program],
    ];
  } else {
    locationsList = locationMap.sortedLists.IN_PERSON[formData.program];
  }

  useEffect(() => {
    setFormData(getFormDataFromUserInfo(userInfo));
  }, [userInfo]);

  const [pageState, setPageState] = useState(PAGE_STATES.INITIAL);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isEnrollmentCenterDropDownOpen, setIsEnrollmentCenterDropDownOpen] =
    useState(false);
  const [phoneConfirmation, setPhoneConfirmation] = useState(null);
  const [showMissingCenterModal, setShowMissingCenterModal] = useState(false);
  const [
    showCurrentInterviewDateWarningModal,
    setShowCurrentInterviewDateWarningModal,
  ] = useState(false);
  const [isOptOutFeedbackModalOpen, setIsOptOutFeedbackModalOpen] =
    useState(false);

  const updateFormData = (field, value) => {
    if (field === "enrollmentCenters" && value.length > 3) {
      // setFormData({
      //   ...formData,
      //   [field]: difference(value, formData.enrollmentCenters),
      // });
      return;
    }
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = (ev, overrideFormData) => {
    const isChangingPhoneNumber = !userInfo?.phone?.includes(formData.phone);
    if (ev) {
      ev.preventDefault();
    }
    setPageState(PAGE_STATES.PENDING);
    message.destroy();
    message.loading("Saving...", 0);

    const formDataToUse = { ...formData, ...overrideFormData };

    return setPreferences({
      ...formDataToUse,
      ...(formDataToUse.phone && { phone: "+1" + formDataToUse.phone }),
    })
      .then((data) => {
        setPageState(PAGE_STATES.SUCCESS);
        message.destroy();
        message.success("Saved!", 2).then(() => {
          setPageState(PAGE_STATES.INITIAL);
        });

        if (isChangingPhoneNumber) {
          window.location.reload();
        }
        fetchUserInfo();

        onSuccess &&
          onSuccess({
            hasEnteredPhone: formDataToUse.phone && formDataToUse.phone.length,
          });

        const wereAnyAlertsOnPreviously =
          userInfo.isEmailEnabled ||
          userInfo.isSmsEnabled ||
          userInfo.isBrowserAlertsEnabled;

        const areAllAlertsOffNow =
          !formDataToUse.isEmailEnabled &&
          !formDataToUse.isSmsEnabled &&
          !formDataToUse.isBrowserAlertsEnabled;
        if (areAllAlertsOffNow && wereAnyAlertsOnPreviously) {
          setIsOptOutFeedbackModalOpen(true);
        }
      })
      .catch((e) => {
        setPageState(PAGE_STATES.ERROR);
        message.destroy();
        message.error("Error saving preferences. Please try again later.", 0);
      });
  };

  handleSubmitRef.current = handleSubmit;

  // cutoff date alerts

  // possible states
  const CUTOFF_DATE_STATES = {
    OK: "OK",
    WARNING: "WARNING",
    ERROR: "ERROR",
  };

  // state variable
  let cutoffDateState = CUTOFF_DATE_STATES.OK;

  // days diff between today and given date in Moment.js format
  const daysBetweenNowAndAnotherDate = (date) => {
    if (date === null) return;
    const today = moment();
    return date.diff(today, "days");
  };

  // updates cutoff date alert state based on days diff between today
  const cutoffDateWarnings = (date) => {
    const daysBetweenNowAndCurrentInterviewDate =
      daysBetweenNowAndAnotherDate(date);

    if (daysBetweenNowAndCurrentInterviewDate < 7) {
      cutoffDateState = CUTOFF_DATE_STATES.WARNING;
    }

    if (daysBetweenNowAndCurrentInterviewDate < 0) {
      cutoffDateState = CUTOFF_DATE_STATES.ERROR;
    }

    if (daysBetweenNowAndCurrentInterviewDate >= 7) {
      cutoffDateState = CUTOFF_DATE_STATES.OK;
    }

    if (isNaN(daysBetweenNowAndCurrentInterviewDate)) {
      cutoffDateState = CUTOFF_DATE_STATES.ERROR;
    }
  };

  // current interview cutoff date in Moment.js format
  const cutoffDateMoment = moment(formData.currentInterviewDate);

  // use current cutoff date to show alerts on initial page load if needed
  cutoffDateWarnings(cutoffDateMoment);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "left",
          padding: "20px",
          maxWidth: "600px",
          minWidth: "250px",
        }}
      >
        <div style={{ width: "100%" }}>
          <div>
            <div>
              {isDashboard && <h2 style={{ fontSize: "18pt" }}>Preferences</h2>}
              {isDashboard && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginTop: "20pt",
                  }}
                >
                  {isDashboard && (
                    <h3 style={{ fontSize: "15pt" }}>
                      Traditional text & email alerts{" "}
                      <span style={{ display: "inline-block" }}>
                        (25 / day)
                      </span>
                    </h3>
                  )}
                  {(userInfo.isEmailEnabled ||
                    userInfo.isSmsEnabled ||
                    userInfo.isBrowserAlertsEnabled) && (
                    <Button
                      type="danger"
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          isEmailEnabled: false,
                          isSmsEnabled: false,
                          isBrowserAlertsEnabled: false,
                        });
                        // updateFormData("isEmailEnabled", false);
                        // updateFormData("isSmsEnabled", false);
                        handleSubmit(e, {
                          ...formData,
                          isEmailEnabled: false,
                          isSmsEnabled: false,
                          isBrowserAlertsEnabled: false,
                        });
                      }}
                    >
                      Turn off all alerts
                    </Button>
                  )}
                </div>
              )}
              {(userInfo.isEmailEnabled || userInfo.isSmsEnabled) && (
                <p style={{ marginBottom: "30px", marginTop: "10px" }}>
                  You can always turn them back on, up until your expiration
                  date{" "}
                  {userInfo.expirationDate &&
                    `(${moment(userInfo.expirationDate).format("LL")})`}
                </p>
              )}
              {numAlertsOnMostRecentDay >= 25 && userInfo.isSmsEnabled && (
                <Alert
                  style={{ marginBottom: "20px" }}
                  message={`${numAlertsOnMostRecentDay} / 25 alerts in the past 24 hours`}
                  description="This limit will automatically reset every 24 hours."
                  type="warning"
                />
              )}
              <Form.Item>
                <h3>
                  <Switch
                    checked={formData.isSmsEnabled}
                    onChange={(checked) =>
                      updateFormData("isSmsEnabled", checked)
                    }
                  ></Switch>
                  <span style={{ marginLeft: "10px" }}>
                    Text message alerts{" "}
                    <SpeedBadge numBolts={2}>Faster</SpeedBadge>
                  </span>
                </h3>
              </Form.Item>
              {isPhoneOptedOut && (
                <Alert
                  message={`We can't text you because you texted STOP to opt out of text messages.`}
                  description={
                    <p>
                      If you want to receive text messages, you'll need to text
                      START to {PHONE_NUMBERS_FOR_UI.TELNYX} to opt back in.
                    </p>
                  }
                  type="warning"
                />
              )}
              <Form.Item label={`Phone number`}>
                <Tooltip
                  title="If you need to change your phone number again, please contact support."
                  {...((!userInfo.phone ||
                    (userInfo.phoneNumberChangeCount < 2 &&
                      userInfo.version > 1)) && {
                    visible: false,
                  })}
                >
                  <div>
                    <Input
                      step={0}
                      addonBefore="+1"
                      maxLength={14}
                      disabled={
                        isDisabled ||
                        (isDashboard &&
                          (userInfo.phoneNumberChangeCount > 1 ||
                            userInfo.version < 2)) ||
                        (isDashboard &&
                          formatPhoneNumber(formData.phone).length < 1 &&
                          !formData.isSmsEnabled)
                      }
                      value={formatPhoneNumber(formData.phone)}
                      placeholder="Phone number"
                      size="large"
                      onChange={(e) => {
                        const newPhoneNumberString = e.target.value;

                        const parsed =
                          parseIncompletePhoneNumber(newPhoneNumberString);
                        const isDeletingCloseParenthesis =
                          formatPhoneNumber(formData.phone).includes(")") &&
                          !newPhoneNumberString.includes(")");

                        if (isDeletingCloseParenthesis) {
                          updateFormData("phone", parsed.slice(0, 2));
                        } else {
                          updateFormData("phone", parsed);
                        }
                      }}
                    />
                    <p>US & Canada numbers only.</p>
                  </div>
                </Tooltip>
              </Form.Item>

              {(!isDashboard ||
                (!userInfo.phone && formData.phone) ||
                (userInfo.phone &&
                  formData.phone !== userInfo.phone.replace("+1", ""))) && (
                <>
                  <Form.Item
                    label="Confirm phone number"
                    hasFeedback={true}
                    validateStatus={
                      formData.phone &&
                      formData.phone.length === 10 &&
                      !(phoneConfirmation === formData.phone)
                        ? "error"
                        : formData.phone && phoneConfirmation === formData.phone
                        ? "success"
                        : ""
                    }
                    help="Please re-type your phone number to confirm"
                  >
                    <Input
                      step={0}
                      addonBefore="+1"
                      maxLength={14}
                      value={formatPhoneNumber(phoneConfirmation)}
                      placeholder="Confirm phone number"
                      size="large"
                      onChange={(e) => {
                        const newPhoneNumberString = e.target.value;

                        const parsed =
                          parseIncompletePhoneNumber(newPhoneNumberString);
                        const isDeletingCloseParenthesis =
                          formatPhoneNumber(phoneConfirmation).includes(")") &&
                          !newPhoneNumberString.includes(")");

                        if (isDeletingCloseParenthesis) {
                          setPhoneConfirmation(parsed.slice(0, 2));
                        } else {
                          setPhoneConfirmation(parsed);
                        }
                      }}
                      disabled={isDisabled}
                    />
                  </Form.Item>

                  <Alert
                    style={{ marginTop: "10px" }}
                    message="By entering your phone number you agree to receive recurring text messages on this number. (Standard messaging rates may apply)."
                    description={
                      <div>
                        <p>
                          We will only use your phone number to send you
                          Appointment Scanner alerts and important account
                          information. We will never send marketing junk.
                        </p>
                        <p>
                          You can expect to receive up to 25 text messages per
                          day.
                        </p>
                        <p>
                          <strong>
                            You can turn off alerts at any time by texting STOP,
                            or by visiting your dashboard, or clicking the
                            unsubscribe link in an email alert.
                          </strong>
                        </p>
                      </div>
                    }
                    type="info"
                  />
                </>
              )}
              <Form.Item style={{ marginTop: "10px" }}>
                <h3 style={{}}>
                  <Switch
                    checked={formData.isEmailEnabled}
                    onChange={(checked) => {
                      updateFormData("isEmailEnabled", checked);
                    }}
                    title={formData.isEmailEnabled ? "on" : "off"}
                  ></Switch>
                  <span style={{ marginLeft: "10px" }}>Email alerts </span>
                  <SpeedBadge numBolts={1}>Slower</SpeedBadge>
                </h3>
              </Form.Item>
              <Form.Item label={`Email address`}>
                <Tooltip title="If you need to change your email address, please reach to customer support">
                  <div>
                    <Input
                      value={firebase.auth().currentUser.email}
                      placeholder="email address"
                      disabled={true}
                      size="large"
                    />
                  </div>
                </Tooltip>
              </Form.Item>
              {isDashboard && (
                <h3 style={{ fontSize: "15pt" }}>Centers & dates</h3>
              )}
              <Form.Item style={{ marginTop: "10px" }}>
                <h4>Which program did you apply for?</h4>
                <Radio.Group
                  size="large"
                  buttonStyle="solid"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      program: event.target.value,
                      enrollmentCenters: [],
                    });
                  }}
                  value={formData.program}
                  disabled={isDisabled}
                >
                  <Radio.Button value={PROGRAM_TYPES.GLOBAL_ENTRY}>
                    Global Entry
                  </Radio.Button>
                  <Radio.Button value={PROGRAM_TYPES.SENTRI}>
                    SENTRI
                  </Radio.Button>
                  <Radio.Button value={PROGRAM_TYPES.NEXUS}>NEXUS</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <h4 id="centers">Choose your enrollment centers (up to 3):</h4>
                {showMissingCenterModal && (
                  <MissingCenterModal
                    onClose={() => setShowMissingCenterModal(false)}
                  />
                )}
                <Select
                  maxTagCount={3}
                  mode="multiple"
                  open={isEnrollmentCenterDropDownOpen}
                  onDropdownVisibleChange={(isOpen) => {
                    setIsEnrollmentCenterDropDownOpen(isOpen);
                  }}
                  placeholder="Search for enrollment centers"
                  value={formData.enrollmentCenters}
                  optionFilterProp="children"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    updateFormData("enrollmentCenters", value);
                    setIsEnrollmentCenterDropDownOpen(false);
                  }}
                  disabled={isDisabled}
                  notFoundContent={
                    window.innerWidth > 560 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "auto",
                        }}
                      >
                        <MdErrorOutline
                          size={20}
                          style={{ margin: "0 12px 2px 0" }}
                        />
                        <p
                          style={{
                            height: "100%",
                            paddingTop: "12px",
                            color: "black",
                          }}
                        >
                          Can't find the enrollment center you're looking for?
                        </p>
                        <Button
                          onClick={() => setShowMissingCenterModal(true)}
                          style={{ marginLeft: "10px" }}
                        >
                          Find out why
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{ whiteSpace: "initial", textAlign: "center" }}
                        >
                          Can't find the enrollment center you're looking for?
                        </p>
                        <Button
                          onClick={() => setShowMissingCenterModal(true)}
                          style={{ marginLeft: "10px" }}
                        >
                          Find out why
                        </Button>
                      </div>
                    )
                  }
                >
                  {locationsList.map((location) => (
                    <Option key={location.id}>
                      {/* {formData.enrollmentCenters.length > 2 &&
                  !formData.enrollmentCenters.includes(location.id) ? (
                    <Tooltip title="You can select up to 3 enrollment centers. Please deselect one to add this enrollment center.">
                      <span>{location.name}</span>
                    </Tooltip>
                  ) : (
                    <span>{location.name}</span>
                  )} */}
                      {location.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <h4 id="cutoff">Latest appointment date to send alerts for:</h4>
                {showCurrentInterviewDateWarningModal && (
                  <CurrentInterviewDateWarningModal
                    onClose={() =>
                      setShowCurrentInterviewDateWarningModal(false)
                    }
                  />
                )}
                <DatePicker
                  size="large"
                  onChange={(date, dateString) => {
                    updateFormData("currentInterviewDate", dateString);

                    const daysBetweenNowAndCurrentInterviewDate =
                      daysBetweenNowAndAnotherDate(date);

                    if (
                      daysBetweenNowAndCurrentInterviewDate < 29 &&
                      daysBetweenNowAndCurrentInterviewDate >= 0
                    ) {
                      setShowCurrentInterviewDateWarningModal(true);
                    }

                    cutoffDateWarnings(date);
                  }}
                  disabledDate={(date) => date.isBefore(moment(), "days")}
                  value={moment(formData.currentInterviewDate)}
                  style={{ width: "100%" }}
                  format={isDatePickerOpen ? "YYYY-MM-DD" : "LL"}
                  onOpenChange={(status) => setIsDatePickerOpen(status)}
                  disabled={isDisabled}
                />
                {cutoffDateState === CUTOFF_DATE_STATES.WARNING && (
                  <Alert
                    message="This date is very soon. You might not get many alerts."
                    type="warning"
                    showIcon
                    style={{ margin: "8px 0" }}
                  />
                )}
                {cutoffDateState === CUTOFF_DATE_STATES.ERROR && (
                  <Alert
                    message="Please select a date in the future."
                    type="error"
                    showIcon
                    style={{ margin: "8px 0" }}
                  />
                )}
                <p style={{ lineHeight: "14px", paddingTop: "5px" }}>
                  (We'll only send you alerts for appointments sooner than this
                  date.)
                </p>
                <p style={{ lineHeight: "14px", paddingTop: "5px" }}>
                  This should be set at least 12 months out for best results, or
                  if you already have an existing interview appointment
                  scheduled, you can set that date here to try to find a sooner
                  one.
                </p>
                {!isDashboard && (
                  <p style={{ lineHeight: "14px" }}>
                    You can leave this field as-is to get alerts about any
                    appointment in the next 12 months.
                  </p>
                )}
              </Form.Item>
              <Form.Item>
                <h4>Days of the week you're available to interview:</h4>
                {formData.excludedDays.length >= 7 && (
                  <Alert
                    message="You must select at least one day."
                    type="error"
                  />
                )}
                <ExcludedDays
                  excludedDays={formData.excludedDays}
                  onChange={(newExcludedDays) =>
                    updateFormData("excludedDays", newExcludedDays)
                  }
                />
              </Form.Item>
              {!isDashboard && (
                <>
                  <p>How did you hear about Appointment Scanner? (optional)</p>{" "}
                  <Select
                    defaultValue={formData.selfReportedReferralSource}
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={(value) => {
                      updateFormData("selfReportedReferralSource", value);
                    }}
                    size="large"
                  >
                    {Object.entries(selfReportedReferralSourceNameMap).map(
                      ([key, name]) => (
                        <Option value={key} key={key}>
                          {name}
                        </Option>
                      )
                    )}
                  </Select>
                  {formData.selfReportedReferralSource === "OTHER" && (
                    <Input
                      placeholder="Enter your own - how did you find Appointment Scanner?"
                      value={formData.selfReportedReferralSourceOtherText}
                      onChange={(event) => {
                        updateFormData(
                          "selfReportedReferralSourceOtherText",
                          event.target.value
                        );
                      }}
                      style={{ marginBottom: "10px" }}
                      size="large"
                    />
                  )}
                </>
              )}
              <Checkbox
                size="large"
                checked={formData.isOptedInToMarketingEmails}
                onChange={(e) =>
                  updateFormData("isOptedInToMarketingEmails", e.target.checked)
                }
                disabled={isDisabled}
                style={{ marginTop: "10px" }}
              >
                It's OK to send me occasional emails about the latest news and
                offerings from Appointment Scanner.
              </Checkbox>
            </div>
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Button
              size="large"
              type="primary"
              style={{
                marginTop: "50px",
                width: "200px",
                marginBottom: "100px",
              }}
              onClick={(e) => handleSubmit(e)}
              disabled={
                isDisabled ||
                !formData.enrollmentCenters.length ||
                (formData.isSmsEnabled &&
                  !(formData.phone && formData.phone.length === 10)) ||
                (userInfo.phone && !formData.phone) ||
                !formData.currentInterviewDate ||
                (!isDashboard &&
                  formData.phone &&
                  formData.phone !== phoneConfirmation) ||
                formData.excludedDays.length >= 7
              }
              loading={pageState === PAGE_STATES.PENDING}
            >
              {pageState === PAGE_STATES.SUCCESS && (
                <div style={{ marginLeft: "10px" }}>
                  <Icon type="check" />
                </div>
              )}
              {(pageState === PAGE_STATES.INITIAL ||
                pageState === PAGE_STATES.ERROR) &&
                "Save preferences"}
            </Button>
          </div>
        </div>
      </div>
      {isOptOutFeedbackModalOpen && (
        <OptOutFeedbackModal
          userInfo={userInfo}
          onClose={() => {
            setIsOptOutFeedbackModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PreferencesForm;
