import moment from "moment";

const MOST_RECENT_PAYMENT_DATE_KEY = "appointment-scanner-last-payment-date";

export const setMostRecentPaymentDateInLocalStorage = () => {
  window.localStorage.setItem(MOST_RECENT_PAYMENT_DATE_KEY, moment().format());
};

export const clearMostRecentPaymentDateInLocalStorage = () => {
  window.localStorage.removeItem(MOST_RECENT_PAYMENT_DATE_KEY);
};

export const getMostRecentPaymentDateInLocalStorage = () => {
  return window.localStorage.getItem(MOST_RECENT_PAYMENT_DATE_KEY);
};

export const getIsMostRecentPaymentDateLessTwentyMinutesAgo = () => {
  const mostRecentPaymentDate = getMostRecentPaymentDateInLocalStorage();

  if (!mostRecentPaymentDate) {
    return false;
  }
  const timeDifference = moment().diff(
    moment(mostRecentPaymentDate),
    "minutes"
  );

  console.log("timeDiff", timeDifference);

  return timeDifference < 20;
};
