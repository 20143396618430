import { Button, Modal } from "antd";
import React from "react";

const CurrentInterviewDateWarningModal = ({ onClose }) => {
  return (
    <Modal
      title={<h3 style={{ margin: 0 }}>Heads up!</h3>}
      visible={true}
      onCancel={() => {
        onClose();
      }}
      footer={
        <div>
          <Button
            onClick={() => {
              onClose();
            }}
            type="primary"
          >
            I understand
          </Button>
        </div>
      }
    >
      <h3>You may want to set this date further out.</h3>
      <h4>
        Appointment Scanner will only send you alerts for appointments sooner
        than the date you set here.
      </h4>
      <h4>
        If you set it just a few weeks out, you will receive fewer alerts.
      </h4>
      <h4>
        We recommend setting this date 12-18 months out to start. (If you end up
        booking an appointment, you can return any time to change this date and
        try to find an even sooner appointment.)
      </h4>
    </Modal>
  );
};

export default CurrentInterviewDateWarningModal;
