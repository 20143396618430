// CardSection.js
import React, { useState, useEffect } from "react";

import { Layout, Row, Col, Menu } from "antd";

const Footer = () => {
  return (
    <Layout.Footer style={{ width: "100vw", padding: "50px 20px" }}>
      <Row>
        <Col md={24}>
          <img src="logo.svg" height="40px" style={{ margin: "20px 0" }} />
        </Col>
        <Col md={24}>
          <Row>
            <Col md={24}>
              <a
                style={{ margin: "0 20px" }}
                target="_blank"
                href="https://appointmentscanner.com/terms.html"
              >
                Terms of service
              </a>
            </Col>
            <Col md={24}>
              <a
                style={{ margin: "0 20px" }}
                target="_blank"
                href="https://appointmentscanner.com/privacy.html"
              >
                Privacy
              </a>
            </Col>
            <Col md={24}>
              <a
                style={{ margin: "0 20px" }}
                target="_blank"
                href="https://appointmentscanner.com/refunds.html"
              >
                Refunds
              </a>
            </Col>
            <Col md={24}>
              <a
                style={{ margin: "0 20px" }}
                href="mailto:support@appointmentscanner.com"
              >
                Contact
              </a>
            </Col>
            <Col md={24}>
              <a
                style={{ margin: "0 20px" }}
                href="https://forms.gle/HPZqvDvTtfM2gJvQA"
              >
                Give feedback
              </a>
            </Col>
            {/* <Col md={24}>
              <i>
                <p style={{ color: "gray", margin: "0 20px" }}>
                  This site may contain affiliate links to other travel partners
                  and deals, which means we may receive a small commission (at
                  no cost to you) if you make a purchase through a link.
                </p>
              </i>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export default Footer;
