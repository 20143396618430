import { Icon, Spin } from "antd";
import React, { useEffect, useState } from "react";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const PaymentPendingScreen = ({ fetchUserInfo }) => {
  const [showLongTimeInfo, setShowLongTimeInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowLongTimeInfo(true);
    }, 20000);

    const interval = setInterval(() => {
      fetchUserInfo();
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2>Processing order...</h2>
      <div>
        <Spin indicator={antIcon} />
      </div>
      <h3>This should just take a few seconds.</h3>

      {showLongTimeInfo && (
        <h4>
          If this takes longer than 5 minutes, please reach out to
          support@appointmentscanner.com or start a chat for assistance.
        </h4>
      )}
    </div>
  );
};

export default PaymentPendingScreen;
