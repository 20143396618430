import React, { Fragment, useState, useEffect } from "react";
import { Button, Input, Icon, Spin } from "antd";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import MagicLinkSignIn from "./MagicLinkSignIn";
import TrainingSection from "./TrainingSection";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

// const RESET_FORM_STATUSES = {
//   INITIAL: "INITIAL",
//   ERROR: "ERROR",
//   SENDING: "SENDING",
//   SUCCESS: "SUCCESS",
// };

const STATUSES = {
  INITIAL: "INITIAL",
  SUBMITTING: "SUBMITTING",
  LOGIN_PENDING: "LOGIN_PENDING",
  SENT: "SENT",
  SUCCESS: "SUCCESS",
  ERROR_SENDING: "ERROR_SENDING",
  ERROR_VERIFYING: "ERROR_VERIFYING",
};

const SignUpStep = () => {
  const [isSignedIn, setIsSignedIn] = useState("");
  // const [showPasswordReset, setShowPasswordReset] = useState(false);
  // const [resetEmail, setResetEmail] = useState("");
  // const [resetFormStatus, setResetFormStatus] = useState(
  //   RESET_FORM_STATUSES.INITIAL
  // );

  const [status, setStatus] = useState(
    window.location.search.includes("oobCode")
      ? STATUSES.LOGIN_PENDING
      : STATUSES.INITIAL
  );

  // const sendPasswordResetEmail = () => {
  //   setResetFormStatus(RESET_FORM_STATUSES.SENDING);
  //   firebase
  //     .auth()
  //     .sendPasswordResetEmail(resetEmail)
  //     .then(() => {
  //       setResetFormStatus(RESET_FORM_STATUSES.SUCCESS);
  //     })
  //     .catch((error) => {
  //       // var errorCode = error.code;
  //       // var errorMessage = error.message;
  //       setResetFormStatus(RESET_FORM_STATUSES.ERROR);
  //     });
  // };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => setIsSignedIn(!!user));

    return () => {
      unregisterAuthObserver();
    };
  }, []);

  // if (showPasswordReset) {
  //   return (
  //     <div style={{ textAlign: "center", fontSize: "13pt" }}>
  //       <p style={{ textAlign: "left" }}>
  //         <Button
  //           onClick={() => {
  //             setShowPasswordReset(!showPasswordReset);
  //           }}
  //           style={{
  //             marginBottom: "20px",
  //           }}
  //         >
  //           Back
  //         </Button>
  //       </p>

  //       {(resetFormStatus === RESET_FORM_STATUSES.INITIAL ||
  //         resetFormStatus === RESET_FORM_STATUSES.SENDING) && (
  //         <>
  //           <div
  //             style={{
  //               marginBottom: "20px",
  //             }}
  //           >
  //             <Input
  //               onChange={(e) => {
  //                 setResetEmail(e.target.value);
  //               }}
  //               value={resetEmail}
  //               placeholder="Email address"
  //               style={{ maxWidth: "350px" }}
  //               size="large"
  //               addonAfter={
  //                 <a
  //                   onClick={sendPasswordResetEmail}
  //                   type="primary"
  //                   disabled={
  //                     !resetEmail.includes(".") || !resetEmail.includes("@")
  //                   }
  //                 >
  //                   {resetFormStatus === RESET_FORM_STATUSES.SENDING
  //                     ? "Sending..."
  //                     : "Send reset email"}
  //                 </a>
  //               }
  //             />
  //           </div>
  //           <p>Need additional help? Reach out to support.</p>
  //         </>
  //       )}

  //       {resetFormStatus === RESET_FORM_STATUSES.SUCCESS && (
  //         <div>
  //           <p style={{ maxWidth: "350px" }}>
  //             <Icon
  //               style={{ fontSize: "50px", color: "#1890ff" }}
  //               type="message"
  //             />
  //           </p>
  //           <p style={{ maxWidth: "350px" }}>
  //             Sent! Check your email ({resetEmail}) for a link to reset your
  //             password.
  //           </p>
  //         </div>
  //       )}

  //       {resetFormStatus === RESET_FORM_STATUSES.ERROR && (
  //         <div>
  //           <p style={{ maxWidth: "350px" }}>
  //             <Icon
  //               style={{ fontSize: "50px", color: "red" }}
  //               type="exclamation-circle"
  //             />
  //           </p>
  //           <p style={{ maxWidth: "350px" }}>
  //             Uh oh. We're having trouble sending you a reset email. Please
  //             double check your email and try again. If this issue persists,
  //             please contact support.
  //           </p>
  //           <Button
  //             onClick={() => {
  //               setResetEmail("");
  //               setResetFormStatus(RESET_FORM_STATUSES.INITIAL);
  //             }}
  //           >
  //             Try again
  //           </Button>
  //         </div>
  //       )}
  //     </div>
  //   );
  // }

  if (!isSignedIn) {
    return (
      <div style={{ textAlign: "center" }}>
        {status !== STATUSES.SENT &&
          status !== STATUSES.LOGIN_PENDING &&
          status !== STATUSES.ERROR_SENDING && (
            <>
              <h1>Welcome</h1>
              <h3>Continue with:</h3>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </>
          )}

        <MagicLinkSignIn
          status={status}
          setStatus={setStatus}
          onSuccess={() => {}}
        />
        {/* <p>
          <a
            onClick={() => {
              setShowPasswordReset(!showPasswordReset);
            }}
            style={{ marginBottom: "40px" }}
          >
            Forget password
          </a>
        </p> */}
        {status !== STATUSES.SENT &&
          status !== STATUSES.LOGIN_PENDING &&
          status !== STATUSES.ERROR_SENDING && (
            <p>
              By proceeding, you agree to our{" "}
              <a href="https://appointmentscanner.com/terms.html">
                terms of service
              </a>{" "}
              and{" "}
              <a href="https://appointmentscanner.com/privacy.html">
                privacy policy.
              </a>
            </p>
          )}
      </div>
    );
  }
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <div>
      {/* <p>
        Welcome {firebase.auth().currentUser.displayName}! You are now
        signed-in!
      </p> */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Spin indicator={antIcon} />
      </div>
      {/* <a onClick={() => firebase.auth().signOut()}>Sign-out</a> */}
    </div>
  );
};

export default SignUpStep;
