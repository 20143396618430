import { Button, Modal, Radio } from "antd";
import React from "react";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { programTypeToName } from "./constants/programTypes";
import { handleSubmitRef } from "./utils/preferenceUtils";
import { submitFeedback } from "./api";

const FORM_STATES = {
  INITIAL: "INITIAL",
  SUBMITTING: "SUBMITTING",
  THANKS: "THANKS",
};

const OptOutFeedbackModal = ({ onClose, userInfo }) => {
  const [formState, setFormState] = useState(FORM_STATES.INITIAL);
  const [nps, setNps] = useState(false);
  const [extra, setExtra] = useState("");
  const [didBookAppointment, setDidBookAppointment] = useState(false);

  const handleSubmit = () => {
    setFormState(false);
    submitFeedback({ nps, didBookAppointment, extra })
      .then(() => {
        if (nps < 4) {
          onClose();
        } else {
          setFormState(FORM_STATES.THANKS);
        }
      })
      .catch((err) => {});
  };
  return (
    <Modal
      title={<h3 style={{ margin: 0 }}>Alerts are off</h3>}
      visible={true}
      onCancel={() => {
        onClose();
      }}
      footer={
        <div>
          <Button
            onClick={() => {
              if (formState === FORM_STATES.THANKS) {
                onClose();
              } else {
                handleSubmit();
              }
            }}
            type="primary"
            loading={formState === FORM_STATES.SUBMITTING}
          >
            Done
          </Button>
        </div>
      }
    >
      {formState !== FORM_STATES.THANKS ? (
        <>
          <h2>Alerts are now off</h2>
          <p style={{ marginBottom: "30px", marginTop: "10px" }}>
            You can always return here to turn them back on, up until your
            account expiration date{" "}
            {userInfo.expirationDate &&
              `(${moment(userInfo.expirationDate).format("LL")})`}
          </p>

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h2>Any feedback?</h2>
            <h3 style={{ paddingLeft: "10px" }}>(optional)</h3>
          </div>

          <h3>Did you book an appointment?</h3>
          <Radio.Group
            buttonStyle="solid"
            onChange={(event) => {
              setDidBookAppointment(event.target.value);
            }}
            value={didBookAppointment}
          >
            <Radio.Button value={"YES"}>Yes</Radio.Button>
            <Radio.Button value={"NO"}>No</Radio.Button>
          </Radio.Group>
          {/* <h3 style={{ marginTop: "20px" }}>Did you book an appointment?</h3>
          <Radio.Group
            // size="large"
            buttonStyle="solid"
            // onChange={(event) => {
            //   setFormData({
            //     ...formData,
            //     program: event.target.value,
            //     enrollmentCenters: [],
            //   });
            // }}
            value={""}
          >
            <Radio.Button value={"YES"}>Yes</Radio.Button>
            <Radio.Button value={"NO"}>No</Radio.Button>
          </Radio.Group> */}
          <h3 style={{ marginTop: "20px" }}>
            How likely are you to recommend Appointment Scanner to a friend?
          </h3>
          <Radio.Group
            // size="large"
            buttonStyle="solid"
            onChange={(event) => {
              setNps(event.target.value);
            }}
            value={nps}
          >
            <Radio.Button value={0}>0</Radio.Button>
            <Radio.Button value={1}>1</Radio.Button>
            <Radio.Button value={2}>2</Radio.Button>
            <Radio.Button value={3}>3</Radio.Button>
            <Radio.Button value={4}>4</Radio.Button>
            <Radio.Button value={5}>5</Radio.Button>
            <Radio.Button value={6}>6</Radio.Button>
            <Radio.Button value={7}>7</Radio.Button>
            <Radio.Button value={8}>8</Radio.Button>
            <Radio.Button value={9}>9</Radio.Button>
            <Radio.Button value={10}>10</Radio.Button>
          </Radio.Group>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              width: "100%",
              justifyContent: "space-between",
              paddingRight: "25px",
              paddingTop: "10px",
            }}
          >
            <p>Not likely</p>
            <p>Extremely likely</p>
          </div>
          <h3 style={{ marginTop: "20px" }}>
            Anything else you want to share? Stories, suggestions,
            complaints–anything!
          </h3>

          <TextArea
            value={extra}
            onChange={(event) => {
              if (event.target.value.length < 2001) {
                setExtra(event.target.value);
              }
            }}
          />
          {extra.length > 1900 && (
            <p>{2000 - extra.length} characters remaining</p>
          )}
        </>
      ) : (
        <>
          <h2>Thanks!</h2>
          <h3>
            If you found Appointment Scanner helpful, please spread the word.
          </h3>
          <h3 style={{ marginTop: "20px" }}>
            <a
              href="https://testimonial.to/appointment-scanner"
              target="_blank"
            >
              Submit a testimonial
            </a>{" "}
          </h3>
          <h3>
            <a
              href="https://appointmentscanner.tapfiliate.com/"
              target="_blank"
            >
              Join our affiliate program
            </a>{" "}
            (for bloggers & influencers)
          </h3>
          <h3>Or, just share on social media!</h3>
          <h3 style={{ marginTop: "30px" }}>
            Enjoy your {userInfo.program && programTypeToName[userInfo.program]}{" "}
            membership!
          </h3>
        </>
      )}
    </Modal>
  );
};

export default OptOutFeedbackModal;
