import { Button, Col, Icon, Row } from "antd";
import React, { useEffect, useState } from "react";
import GettingStartedGuide from "./GettingStartedGuide";
import range from "lodash.range";
import { IoBulbOutline } from "react-icons/io5";

// illustrations
import EmailAlert from "./assets/training/emailAlert.svg";
import SmsAlert from "./assets/training/smsAlert.png";
import SmsAlertNoBadge from "./assets/training/smsAlertNoBadge.png";
import WebAlert from "./assets/training/webAlert.svg";
import LoginTTP from "./assets/training/loginTTP.svg";
import ScheduleInterviewButton from "./assets/training/scheduleInterview.svg";
import DateFilter from "./assets/training/dateFilters.svg";
import NoAppointments from "./assets/training/noAppointments.svg";
import AppointmentError from "./assets/training/appointmentError.svg";
import TurnOffAlerts from "./assets/training/turnOffAllAlerts.svg";
import TextStop from "./assets/training/textStop.png";

const StepContainer = ({ children }) => {
  return (
    <div
      className="step-container"
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      {children}
    </div>
  );
};

const Step = ({ children, header, subheader, tips }) => {
  return (
    <StepContainer>
      <Row>
        <Col
          className="training-header-container"
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={18}
        >
          <StepHeader>{header}</StepHeader>
          {subheader && <StepSubHeader>{subheader}</StepSubHeader>}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={6}>
          {tips && (
            <TipsBox>
              <ol style={{ paddingInlineStart: "10px" }}>
                {tips.map((t) => {
                  return (
                    <>
                      <li>{t}</li>
                      <br />
                    </>
                  );
                })}
              </ol>
            </TipsBox>
          )}
        </Col>
      </Row>
      {children}
    </StepContainer>
  );
};

const StepHeader = ({ children }) => {
  return (
    <h1
      class="training-step-header"
      style={{
        color: "white",
        fontWeight: 800,
        textAlign: "left",
      }}
    >
      {children}
    </h1>
  );
};

const StepSubHeader = ({ children }) => {
  return (
    <h2
      class="training-step-subheader"
      style={{ color: "white", fontWeight: 500 }}
    >
      {children}
    </h2>
  );
};
const StepIndicator = ({ numSteps, currentStep }) => {
  const stepsArray = range(0, numSteps);
  return (
    <div
      style={{
        display: "flex",
        width: "100px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {stepsArray.map((i) => (
        <div
          style={{
            borderRadius: "100px",
            width: "5px",
            height: "5px",
            backgroundColor: currentStep >= i ? "#448EF7" : "lightgray",
          }}
        ></div>
      ))}
    </div>
  );
};

const TipsBox = ({ children }) => {
  return (
    <div
      id="tips-box-container"
      style={{
        background: "#EDC665",
        padding: "20px 20px 0 20px",
        borderRadius: "15px",
        right: 0,
        top: 0,
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        fontWeight: "700",
      }}
    >
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <h3>Tips</h3>
        <div style={{ width: "100%" }} />
        <IoBulbOutline size={20} />
      </div>
      {children}
    </div>
  );
};

const TrainingSection = ({ onComplete = () => {}, onSkip = () => {} }) => {
  const MOBILE_BREAKPOINT = 600;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
  };

  window.addEventListener("resize", handleResize);

  const isOnDashboard = window.location.pathname.includes("dashboard");
  useEffect(() => {
    window.$crisp && window.$crisp.push(["do", "chat:hide"]);

    return () => window.$crisp && window.$crisp.push(["do", "chat:show"]);
  }, []);
  const [currentStep, setCurrentStep] = useState(0);
  return (
    <div
      className="training-container"
      style={{
        textAlign: "left",
        display: "flex",
        height: "100%",
        position: "relative",
        borderRadius: "5px",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#448EF7",
          overflowY: "scroll",
        }}
      >
        {isMobile && currentStep > 0 && (
          <div
            className="training-menu-parent"
            style={{
              textAlign: "center",
              position: "absolute",
              top: 0,
              height: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <div
              className="training-menu-child"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "white",
              }}
            >
              <Button
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
                disabled={currentStep <= 0}
                size="large"
              >
                Back
              </Button>
              <StepIndicator numSteps={7} currentStep={currentStep} />
              <Button
                onClick={() => {
                  if (currentStep >= 6) {
                    onComplete();
                  } else {
                    setCurrentStep(currentStep + 1);
                  }
                }}
                type="primary"
                size="large"
              >
                {currentStep === 6
                  ? isOnDashboard
                    ? "Close"
                    : "Go to Dashboard"
                  : "Next"}
              </Button>
            </div>
          </div>
        )}
        {isMobile && <div style={{ height: "60px" }} />}
        {currentStep === 0 && (
          <Step
            header={
              isOnDashboard
                ? "Getting started guide"
                : "Final step to continue to your dashboard"
            }
            subheader={<>Learn the basics of Appointment Scanner</>}
          >
            <>
              <div
                className="training-start-button-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div>
                  <Button
                    className="start-training-button"
                    color="white"
                    style={{ color: "#448EF7" }}
                    size="large"
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                  >
                    <strong>Learn to book appointments</strong> (2 mins)
                  </Button>
                </div>

                {!window.location.pathname.includes("dashboard") && (
                  <div>
                    <a
                      style={{
                        color: "white",
                        textDecoration: "underline",
                        fontSize: "12pt",
                        paddingBottom: "20px",
                      }}
                      onClick={onSkip}
                    >
                      or skip and go to your dashboard
                    </a>
                  </div>
                )}

                <div
                  className="training-images-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "1",
                    bottom: 0,
                    position: "relative",
                  }}
                >
                  <img
                    className="training-image training-phone-img"
                    src={SmsAlertNoBadge}
                    alt="Illustration showing SMS alert from AppointmentScanner.com"
                    style={{
                      maxHeight: "300px",
                      height: "auto",
                      bottom: 0,
                      position: "relative",
                      // width: "100%",
                    }}
                  />
                </div>
              </div>
            </>
          </Step>
        )}

        {currentStep === 1 && (
          <Step
            header="How does Appointment Scanner work?"
            subheader={
              <>
                <p>
                  Even if the TTP website shows “No Appointments Available” new
                  appointments are constantly released.
                </p>
                <p>
                  Appointments become available for two reasons:{" "}
                  <strong>last-minute cancellations</strong> and{" "}
                  <strong>
                    enrollment centers releasing big batches of new appointments
                  </strong>
                </p>
              </>
            }
            tips={[
              "Last minute cancellations are harder to claim. There are thousands of people refreshing the TTP website at any given time.",
              "New appointments are released in batches so stay around for a couple weeks to see them!",
            ]}
          >
            <>
              {/* vertical spacer */}
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  paddingBottom: "50px",
                }}
              >
                <div
                  style={{ display: "flex" }}
                  className="training-images-container"
                >
                  <img
                    className="training-image"
                    style={{ padding: "40px" }}
                    src={NoAppointments}
                    alt="Illustration showing no available apointments on the Trusted Traveler Program website."
                  />
                </div>
              </div>
            </>
          </Step>
        )}
        {currentStep === 2 && (
          <Step
            header="You will start receiving web browser/text message/email alerts soon"
            subheader="Appointments are often taken within a minute or two at busy centers so react as fast as you can. Expect to use Appointment Scanner for a couple of weeks."
            tips={[
              "This is just an alerting service, we can’t book or hold appointments on your behalf. We can only notify you when openings occur.",
              "Browser alerts are best for when you are on your computer and text message/email alerts can alert you while away!",
            ]}
          >
            <>
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                className="training-images-container"
                style={{ display: "flex", justifyContent: "center", flex: "1" }}
              >
                <img
                  id="training-web-alert-image"
                  className="training-image"
                  src={WebAlert}
                  alt="Illustration showing web browser alert from AppointmentScanner.com"
                  style={{
                    maxHeight: "300px",
                    width: "100%",
                    width: "auto",
                  }}
                />
                <img
                  className="training-image training-phone-img"
                  src={SmsAlert}
                  alt="Illustration showing SMS alert from AppointmentScanner.com"
                  style={{
                    maxHeight: "300px",
                    width: "100%",
                    width: "auto",
                  }}
                />
                <img
                  id="training-email-alert-image"
                  className="training-image"
                  src={EmailAlert}
                  alt="Illustration showing email alert from AppointmentScanner.com"
                />
              </div>
            </>
          </Step>
        )}
        {currentStep === 3 && (
          <Step
            header="After you’re alerted, go to the Trusted Traveler Program website"
            subheader="Either click the link in the alert or navigate to the TTP homepage on your computer, phone, or tablet. "
            tips={[
              "If you want to reschedule, don’t ever click “CANCEL INTERVIEW” only “RESCHEDULE INTERVIEW” or you will lose your existing appointment",
              "When you login, click “Remember this browser” so you can login quicker next time",
            ]}
          >
            <>
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                className="training-images-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  color: "white",
                }}
              >
                <div
                  className="training-login-container"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p
                    className="training-image-instruction-step"
                    id="training-login-instructions-text"
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    1. Log in at https://ttp.dhs.gov with the account you were
                    pre-approved on.
                  </p>
                  <img
                    className="training-image"
                    id="training-schedule-button-image"
                    src={LoginTTP}
                    alt="Illustration showing how to login to Trusted Traveler Program website."
                    style={{ padding: "0 0 20px 0" }}
                  />
                </div>
                <div
                  id="training-schedule-interview-container"
                  className="mobile-margin-right-40px training-login-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 40px",
                  }}
                >
                  <p
                    id="training-click-schedule-text"
                    className="training-image-instruction-step"
                    style={{
                      fontWeight: "700",
                      paddingLeft: "44px",
                    }}
                  >
                    2. Click the <strong>“Schedule Interview”</strong> or
                    <strong>“Reschedule Interview”</strong> button.
                  </p>
                  <img
                    id="training-schedule-button-image"
                    className="training-image mobile-padding-bottom-20px"
                    style={{ maxHeight: "300px", padding: "0 0 25px 0" }}
                    src={ScheduleInterviewButton}
                    alt="Illustration showing the schedule interview button on Trusted Traveler Program website."
                  />
                </div>
              </div>
            </>
          </Step>
        )}
        {currentStep === 4 && (
          <Step header="Don’t use the date filters!">
            <>
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                className="training-images-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  color: "white",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      className="training-image-instruction-step"
                      style={{ fontWeight: "700", fontSize: "18px" }}
                    >
                      Ignore the date filters at the top of the scheduler page.
                      The filters are misleading and will often hide the
                      appointment you are looking for.
                    </p>
                  </div>
                  <div className="training-date-filter-vertical-spacer" />
                  <div style={{ display: "flex" }}>
                    <p
                      className="training-image-instruction-step"
                      style={{ fontWeight: "700", fontSize: "18px" }}
                    >
                      Scroll down to the enrollment center name and click it to
                      fetch the soonest appointments for that center.
                    </p>
                  </div>
                </div>
                <img
                  id="training-date-filters-img"
                  className="training-image"
                  src={DateFilter}
                  alt="Illustration showing the date filters on the Trusted Traveler Program website."
                />
              </div>
            </>
          </Step>
        )}
        {currentStep === 5 && (
          <Step
            header="This might not work on your first try!"
            subheader={
              <>
                <p>
                  While some are lucky and secure an appointment within hours of
                  signing up, in most cases it takes a week or two.
                </p>
                <p>
                  If you don’t see the appointment or get an error message when
                  you try to book it, it means someone else grabbed it already.
                  Thousands of people refresh the scheduler page daily so
                  appointment disappear fast but with Appointment Scanner your
                  odds are good if you stick with it.
                </p>
              </>
            }
            tips={[
              "Stick with it for a couple weeks even if you find it challenging to book an appointment. Centers will add batches of new appointments every couple of weeks which are usually easier to claim!",
            ]}
          >
            <>
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  color: "white",
                }}
              >
                <div
                  style={{ display: "flex" }}
                  className="training-images-container"
                >
                  <img
                    className="training-image"
                    style={{ padding: "40px" }}
                    src={NoAppointments}
                    alt="Illustration showing no available apointments on the Trusted Traveler Program website."
                  />
                  <img
                    className="training-image"
                    style={{ padding: "40px" }}
                    src={AppointmentError}
                    alt="Illustration showing error when scheduling appointment"
                  />
                </div>
              </div>
            </>
          </Step>
        )}
        {currentStep === 6 && (
          <Step
            header="What happens after I schedule my appointment?"
            subheader={
              <>
                <p>
                  Even if you book an appointment, you will still receive alerts
                  up to 1 month after your purchase date unless you disable
                  them.
                </p>
                <p>
                  You can login to the Appointment Scanner Dashboard to turn off
                  alerts for SMS or email or text “STOP” to the number you
                  receive alerts from to unusbscribe.
                </p>
              </>
            }
            tips={[
              "Most customers only need 1 month, but if you need more time login to Appointment Scanner after your account has expired and follow the steps on the Dashboard to purchase another month.",
              "You can reschedule your appointment as often as you want so you can keep trying to get the best appointment possible.",
            ]}
          >
            <>
              <div style={{ display: "flex", flexGrow: "1" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  color: "white",
                }}
              >
                <div
                  className="training-images-container"
                  style={{ display: "flex" }}
                >
                  <img
                    id="training-turn-off-alerts-img"
                    className="training-image"
                    style={{ padding: "0 20px" }}
                    src={TurnOffAlerts}
                    alt="Illustration showing how to disable all alerts in Appointment Scanner dashboard"
                  />
                  <img
                    className="training-image training-phone-img"
                    style={{
                      maxHeight: "300px",
                      width: "auto",
                      height: "300px",
                      padding: "0 20px",
                    }}
                    src={TextStop}
                    alt="Illustration showing how to text STOP to turn off SMS alerts."
                  />
                </div>
              </div>
            </>
          </Step>
        )}
      </div>
      {!isMobile && currentStep > 0 && (
        <div
          className="training-menu-parent"
          style={{
            textAlign: "center",
            position: "absolute",
            bottom: 0,
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <div
            className="training-menu-child"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
            }}
          >
            <Button
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              disabled={currentStep <= 0}
              size="large"
            >
              Back
            </Button>
            <StepIndicator numSteps={7} currentStep={currentStep} />
            <Button
              onClick={() => {
                if (currentStep >= 6) {
                  onComplete();
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }}
              type="primary"
              size="large"
            >
              {currentStep === 6
                ? isOnDashboard
                  ? "Close"
                  : "Go to Dashboard"
                : "Next"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainingSection;
