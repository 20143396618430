import React, { useEffect } from "react";
import * as firebase from "firebase/app";
import { isQA } from "./utils/domainUtils";
import { setPaymentPending } from "./api";
import {
  getIsMostRecentPaymentDateLessTwentyMinutesAgo,
  setMostRecentPaymentDateInLocalStorage,
} from "./utils/paymentUtils";
import "firebase/analytics";
//this is a workaround because there is no API to close the Paddle checkout once it's opened

const closePaddleCheckout = () => {
  const paddleIframes = document.getElementsByClassName("paddle-frame");

  if (paddleIframes && paddleIframes.length) {
    paddleIframes[0].remove();
  } else {
    //in case they ever change the class name, reloading works too
    window.location.reload();
  }
};

const PADDLE_VENDOR_ID_PROD = 121993;
const PADDLE_VENDOR_ID_SANDBOX = 8122;

const PADDLE_VENDOR_ID = isQA()
  ? PADDLE_VENDOR_ID_SANDBOX
  : PADDLE_VENDOR_ID_PROD;

const PADDLE_PRODUCT_ID_PROD = 635954;
const PADDLE_PRODUCT_ID_SANDBOX = 35276;

const PADDLE_PRODUCT_ID = isQA()
  ? PADDLE_PRODUCT_ID_SANDBOX
  : PADDLE_PRODUCT_ID_PROD;

const Paddle = window.Paddle;

if (isQA()) {
  window.Paddle.Environment.set("sandbox");
}

Paddle.Setup({
  vendor: PADDLE_VENDOR_ID, // Replace with your Vendor ID.

  // eventCallback: function (data) {
  //   if (data.event === "Checkout.Complete") {
  //     //Do tracking here
  //   }
  // },
});

const PaddleCheckoutForm = ({
  onSuccess,
  fetchUserInfo,
  setHasPaidLocally,
  passthrough,
}) => {
  //Workaround to ensure users can never pay twice across multiple windows/tabs, using localstorage to communicate cross window
  useEffect(() => {
    const interval = setInterval(() => {
      fetchUserInfo();
      if (getIsMostRecentPaymentDateLessTwentyMinutesAgo()) {
        closePaddleCheckout();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      closePaddleCheckout();
    };
  }, []);

  const trackConversionWithFirebaseAnalytics = (data) => {
    const analytics = firebase.analytics();

    var order_amount = (
      data.checkout.prices.vendor.total - data.checkout.prices.vendor.total_tax
    ).toFixed(2);

    analytics.logEvent("purchase", {
      order_amount,
    });
  };

  const trackAffiliateConversionTemp = (data) => {
    console.log("tapfiliate - trackAffiliateConversionTemp");

    const analytics = firebase.analytics();

    analytics.logEvent("affiliate_conversion_temp");
  };

  useEffect(() => {
    console.log("calling paddle checkout open");

    if (getIsMostRecentPaymentDateLessTwentyMinutesAgo()) {
      window.location.reload();
    }

    Paddle.Checkout.open({
      // method: "inline",
      product: PADDLE_PRODUCT_ID, // Replace with your Product or Plan ID
      allowQuantity: false,
      // disableLogout: true,
      // frameTarget: "checkout-container", // The className of your checkout <div>
      // frameInitialHeight: 416,
      // frameStyle:
      //   "width:495px; min-width:495px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 495px
      email: firebase.auth().currentUser.email,
      passthrough: JSON.stringify({
        userId: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        ...passthrough,
      }),
      successCallback:
        onSuccess ||
        ((data) => {
          console.log("payment callback");

          const setPaid = () => {
            //conversions are being missed for some reason, add a temporary delay to see if there's a race condition
            setTimeout(() => {
              console.log("tracked");
              setMostRecentPaymentDateInLocalStorage();
              setHasPaidLocally(true);
              setPaymentPending().then(() => {
                fetchUserInfo();
              });
            }, 5000);
          };
          trackConversionWithFirebaseAnalytics(data);

          if (window.trackConversionWithTapfiliate) {
            console.log("tapfiliate - trackConversion exists");
            trackAffiliateConversionTemp();

            const callback = (error, result) => {
              console.log("tapfiliate- In callback after track conversion");
              const analytics = firebase.analytics();

              if (error) {
                console.log("tapfiliate- error tracking conversion", error);
                analytics.logEvent("affiliate_conversion_temp_error", {
                  errorMsg: error.message,
                });
              } else {
                console.log("tapfiliate- success tracking conversion", result);
                analytics.logEvent("affiliate_conversion_temp_success");
              }
              setPaid();
            };
            console.log("tapfiliate- going to track conversion");
            window.trackConversionWithTapfiliate(data, callback);
          } else {
            console.log("tapfiliate- trackconversion does not exist");

            setPaid();
          }
        }),
    });
  }, []);

  console.log("rendering padddle checkout component");

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <div className="checkout-container"></div> */}
    </div>
  );
};

export default PaddleCheckoutForm;
