import React from "react";
import { MdBolt } from "react-icons/md";

const SpeedBadge = ({ numBolts, children, style }) => {
  let bolts = [];

  for (let i = 0; i < numBolts; i++) {
    bolts.push(
      <div
        style={{
          width: "15px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <MdBolt />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#E2745D",
        color: "white",
        borderRadius: "50px",
        height: "25px",
        padding: 0,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex",
        padding: "5px 10px",
        fontSize: ".9rem",
        fontWeight: "500",
        ...style,
      }}
    >
      {bolts}
      {children}
    </div>
  );
};

export default SpeedBadge;
