// CardSection.js
import React, { useState, useEffect, Fragment, useContext } from "react";
import PreferencesForm from "./PreferencesForm";
import * as firebase from "firebase/app";
import { clearRenewalPaymentPending, getUserInfo } from "./api";
import SignUpStep from "./SignUpStep";
import {
  Icon,
  Spin,
  Statistic,
  Col,
  Card,
  Table,
  Divider,
  Alert,
  Button,
  notification,
  Modal,
} from "antd";
import moment from "moment";
import { memoize } from "underscore";
import GettingStartedGuide from "./GettingStartedGuide";
import RenewAccountCheckout from "./RenewAccountCheckout";
import TrainingSection from "./TrainingSection";
import {
  LOCATION_MAP_STATUSES,
  UserInfoContext,
  USER_INFO_STATUSES,
} from "./utils/userInfoUtils";
import BrowserAlertsSection from "./BrowserAlertsSection";
import LimitsSection from "./LimitsSection";
import { getNameForLocationId } from "./utils/locationUtils";

const PreferencesPageContainer = () => {
  const {
    userInfo,
    fetchUserInfo,
    locationMap,
    isAuthenticated,
    isPhoneOptedOut,
  } = useContext(UserInfoContext);

  const hasNoAccount =
    userInfo.userInfoStatus === USER_INFO_STATUSES.LOADED_HAS_NO_ACCOUNT;
  const [didSignUpDuringShudown, setDidSignUpDuringShudown] = useState(false);
  const [showGettingStartedModal, setShowGettingStartedModal] = useState(false);

  const isRenewalPaymentPending = userInfo && userInfo.isRenewalPaymentPending;

  const isAwaitingLoginRefresh =
    !isAuthenticated && localStorage.getItem("PREVIOUSLY_SIGNED_IN");

  const isAuthenticatedAndAwaitingUserInfo =
    isAuthenticated && userInfo.userInfoStatus === USER_INFO_STATUSES.INITIAL;

  const isAuthedAndHasLoadedUserInfoAndHasAccount =
    isAuthenticated &&
    userInfo.userInfoStatus === USER_INFO_STATUSES.LOADED_HAS_ACCOUNT;

  const hasUserInfoErrored =
    isAuthenticated && userInfo.userInfoStatus === USER_INFO_STATUSES.ERRORED;

  const isLocationMapLoading =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.INITIAL;

  const isLocationMapLoaded =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.LOADED;

  const hasLocationMapErrored =
    locationMap.metadata.status === LOCATION_MAP_STATUSES.ERRORED;

  const [showRenewAccountCheckout, setShowRenewAccountCheckout] = useState(
    isRenewalPaymentPending
  );

  useEffect(() => {
    if (isRenewalPaymentPending) {
      setShowRenewAccountCheckout(true);
    }
  }, [isRenewalPaymentPending]);

  useEffect(() => {
    if (
      window.location.search.includes("ft=true") &&
      !localStorage.getItem("hideDashboardTooltip")
    )
      notification.open({
        message: "Welcome to your dashboard",
        description:
          "You can return here any time to change your alert preferences, view account info, and contact support. ",
        icon: <Icon type="info-circle" style={{ color: "#108ee9" }} />,
        onClose: () => {
          localStorage.setItem("hideDashboardTooltip", true);
        },
        duration: 10,
      });
  }, []);

  const handleSignOutClick = () => {
    firebase.auth().signOut();
    window.location.reload();
  };

  const columns = [
    {
      title: "Enrollment center",
      dataIndex: "locationId",
      key: "location",
      render: (locationId) => (
        <p>{getNameForLocationId(locationId, locationMap)}</p>
      ),
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "time",
      render: (timestamp) => <p>{moment(timestamp).format("LLLL")}</p>,
    },
  ];

  const needsToVerifySms =
    userInfo.isSmsEnabled &&
    userInfo.phone &&
    userInfo.version &&
    userInfo.version >= 2 &&
    !userInfo.isSmsVerified;

  console.log("userInfo", userInfo);

  const hasNotFinishedInitialSignUp =
    !userInfo.isEnabled && !userInfo.enrollmentCenters?.length;
  const paymentHasNotProcessed = !userInfo.paymentDate;

  if (
    isAuthedAndHasLoadedUserInfoAndHasAccount &&
    (paymentHasNotProcessed || hasNotFinishedInitialSignUp || needsToVerifySms)
  ) {
    window.location = "/";
    return null;
  }

  if (hasLocationMapErrored || hasUserInfoErrored) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          direction: "column",
          marginTop: "100px",
        }}
      >
        <Alert
          type="error"
          message="We're having trouble loading this page :("
          description="Please refresh the page and try again. If this issue persists, contact support."
          showIcon={true}
        ></Alert>
      </div>
    );
  }

  if (
    isAwaitingLoginRefresh ||
    isAuthenticatedAndAwaitingUserInfo ||
    isLocationMapLoading
  ) {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} />;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          direction: "column",
          padding: "20px",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  if (hasNoAccount) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "100vw",
        }}
      >
        <div style={{ maxWidth: "100vw" }}>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <h1>No user account found.</h1>
            <h2>
              Make sure you're signing in with the same service you used to
              create your account.
            </h2>
            <Button type="primary" onClick={handleSignOutClick}>
              Sign in with a different account
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        width: "100vw",
      }}
    >
      <Modal
        title={
          <h3 style={{ margin: 0 }}>
            Getting started with Appointment Scanner
          </h3>
        }
        visible={showGettingStartedModal}
        width="100vw"
        height="100vh"
        style={{
          marginLeft: 0,
          marginRight: 0,
          position: "fixed",
          top: "50px",
          right: 0,
          left: 0,
          bottom: 0,
          overflow: "hidden",
        }}
        onCancel={() => {
          setShowGettingStartedModal(false);
        }}
        footer={
          null
          // <div>
          //   <Button
          //     onClick={() => {
          //       setShowGettingStartedModal(false);
          //     }}
          //     type="primary"
          //   >
          //     Close
          //   </Button>
          // </div>
        }
      >
        <div
          style={{
            minHeight: "60vh",
            height: "calc(80vh)",
            position: "relative",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TrainingSection
            onComplete={() => {
              setShowGettingStartedModal(false);
            }}
          />
          {/* <h2 style={{ marginTop: "20px" }}>Still have questions?</h2>
          <p>
            Email us at{" "}
            <a href="mailto:support@appointmentscanner.com">
              support@appointmentscanner.com
            </a>{" "}
            or{" "}
            <a
              onClick={() => {
                window.$crisp && window.$crisp.push(["do", "chat:open"]);
                // window.HubSpotConversations &&
                //   window.HubSpotConversations.widget.open();
                setShowGettingStartedModal(false);
              }}
            >
              start a chat
            </a>
          </p> */}
        </div>
      </Modal>

      <div style={{ maxWidth: "100vw" }}>
        <div style={{ padding: "20px" }}>
          {isAuthedAndHasLoadedUserInfoAndHasAccount ? (
            <div>
              <h1>Dashboard</h1>
              <Fragment>
                {showRenewAccountCheckout && (
                  <RenewAccountCheckout
                    userInfo={userInfo}
                    fetchUserInfo={fetchUserInfo}
                    onClose={() => setShowRenewAccountCheckout(false)}
                  />
                )}
                {userInfo.isEnabled ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowGettingStartedModal(true);
                    }}
                  >
                    <Alert
                      message="How to use Appointment Scanner"
                      description={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // marginBottom: "20px",
                          }}
                        >
                          <p>2-minute read</p>
                          <Button
                            onClick={() => {
                              setShowGettingStartedModal(true);
                            }}
                          >
                            Read
                          </Button>
                        </div>
                      }
                      style={{ marginBottom: "20px" }}
                    />
                  </div>
                ) : (
                  <div>
                    <Alert
                      type="error"
                      message={
                        <h2>
                          Account{" "}
                          {userInfo.isRefunded ? "cancelled" : "expired"}
                        </h2>
                      }
                      description={
                        <div>
                          <br />
                          <p>Thanks so much for using Appointment Scanner!</p>
                          <p>
                            Let me know if you have any ideas for how to make
                            the site better and be sure to spread to word to
                            other travelers!
                          </p>
                          <p>- Derek</p>
                          <i>
                            <p>Owner @ Appointment Scanner</p>
                          </i>
                          <br />
                          <h3>Need another month?</h3>

                          {userInfo?.version > 1 && !isRenewalPaymentPending ? (
                            <>
                              <br />
                              <h4>
                                Add another month of alerts to your account
                                ($29)
                              </h4>
                              <ul>
                                <li>One-time payment. Not a subscription.</li>
                                <li>Up to 25 alerts per day, for one month.</li>
                              </ul>
                              <Button
                                type="primary"
                                onClick={() => {
                                  clearRenewalPaymentPending()
                                    .then(() => {
                                      setShowRenewAccountCheckout(true);
                                      // setUserInfo({
                                      //   ...userInfo,
                                      //   isPaymentPending: false,
                                      // });
                                    })
                                    .catch(() => {
                                      // setFormState(
                                      //   FORM_STATES.ERROR_CLEARING_PAYMENT_PENDING
                                      // );
                                      alert(
                                        "We're having trouble renewing your account. Please reach out to support and we'll help you out."
                                      );
                                    });
                                }}
                              >
                                Add another month of alerts ($29)
                              </Button>
                              <p
                                style={{
                                  fontSize: "9pt",
                                  marginTop: "10px",
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                Plus sales tax calculated at checkout
                              </p>
                            </>
                          ) : (
                            <>
                              <br />
                              <p>
                                Send an email to{" "}
                                <a href="mailto:support@appointmentscanner.com">
                                  support@appointmentscanner.com
                                </a>{" "}
                                and I'll help you add another month to your
                                account ($29).
                              </p>
                            </>
                          )}
                          <br />
                        </div>
                      }
                      style={{ marginBottom: "20px", maxWidth: "600px" }}
                    />
                  </div>
                )}
                <div
                  style={{
                    marginBottom: "50px",
                    maxWidth: "600px",
                    minWidth: "250px",
                    marginRight: 0,
                  }}
                >
                  <h3>Account info </h3>
                  <Card style={{ width: "100%" }}>
                    {/* <Col span={12}>
                      <Statistic
                        title="Past appointment openings found"
                        value={userInfo.slotsSeen.length}
                      />
                    </Col> */}
                    <Col span={12}>
                      {userInfo.isRefunded ? (
                        <Statistic
                          title="Account status"
                          value={"Cancelled & refunded"}
                        />
                      ) : (
                        <Statistic
                          title="Account expiration date"
                          value={
                            moment(userInfo.expirationDate).format("LL") +
                            (didSignUpDuringShudown ? "*" : "")
                          }
                        />
                      )}
                    </Col>
                    <Col span={24}>
                      <p style={{ marginTop: "20px", fontSize: "16px" }}>
                        <Icon type="info-circle" style={{ color: "#108ee9" }} />{" "}
                        <strong>
                          This is not a subscription. You will not be charged
                          again.
                        </strong>
                      </p>
                      {userInfo.isEnabled && (
                        <p>
                          Need more time? After your account expires, log in to
                          purchase an additional month of alerts.
                        </p>
                      )}
                    </Col>
                    {didSignUpDuringShudown && (
                      <Col span={24}>
                        <Alert
                          style={{ marginTop: "10px" }}
                          message={
                            <span>
                              *Since you signed up while enrollment centers were
                              closed due to COVID-19, we've extended your access
                              for free. You'll have access for 3 months from
                              September 8th, 2020 when the centers reopened.
                              Happy appointment scanning!
                            </span>
                          }
                          type="success"
                          showIcon
                        />
                      </Col>
                    )}
                  </Card>
                </div>
                {/* <LimitsSection userInfo={userInfo} /> */}

                <BrowserAlertsSection
                  userInfo={userInfo}
                  fetchUserInfo={fetchUserInfo}
                  locationMap={locationMap}
                />

                {/* <div style={{ maxWidth: "600px", minWidth: "250px" }}>
                  {Boolean(userInfo.slotsSeen && userInfo.slotsSeen.length) && (
                    <Fragment>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <h3>
                          Past appointment openings found (
                          {userInfo.slotsSeen.length})
                        </h3>
                      </div>
                      <Table
                        pagination={{ pageSize: 5 }}
                        columns={columns}
                        size="small"
                        dataSource={userInfo.slotsSeen.map((slot, i) => ({
                          ...slot,
                          key: i,
                        }))}
                      />
                    </Fragment>
                  )}
                </div> */}
              </Fragment>

              <Divider />
              <div>
                <PreferencesForm
                  userInfo={userInfo}
                  isDashboard={true}
                  fetchUserInfo={fetchUserInfo}
                  isDisabled={!userInfo.isEnabled}
                  locationMap={locationMap}
                  isPhoneOptedOut={isPhoneOptedOut}
                />
              </div>
              {/* <PerksSection programType={userInfo.program} /> */}
            </div>
          ) : (
            <SignUpStep />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreferencesPageContainer;
