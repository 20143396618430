import { Alert, Button, Icon } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import {
  removePhoneNumber,
  resendSmsVerificationCode,
  setPreferences,
  verifySmsCode,
} from "./api";
import { PHONE_NUMBERS_FOR_UI } from "./constants/phoneNumbers";
import { formatPhoneNumber } from "./utils/phoneUtils";

const MAX_PHONE_NUMBER_CHANGES = 2; //also on backend

const FORM_STATES = {
  INITIAL: "INITIAL",
  PARTIAL: "PARTIAL",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  WRONG_CODE_ERROR: "WRONG_CODE_ERROR",
  TOO_MANY_ATTEMPTS_ERROR: "TOO_MANY_ATTEMPTS_ERROR",
};

const RESEND_CODE_STATES = {
  INITIAL: "INITIAL",
  ERROR: "ERROR",
  SENDING: "SENDING",
  SUCCESS: "SUCCESS",
  ALREADY_SENT: "ALREADY_SENT",
};

const SmsVerifyForm = ({
  onSuccess,
  userInfo,
  fetchUserInfo,
  isPhoneOptedOut,
}) => {
  const [formState, setFormState] = useState(FORM_STATES.INITIAL);
  const [resendCodeState, setResendCodeState] = useState(
    userInfo.hasResentSmsVerificationCode
      ? RESEND_CODE_STATES.ALREADY_SENT
      : RESEND_CODE_STATES.INITIAL
  );

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleComplete = (code) => {
    setFormState(FORM_STATES.LOADING);
    verifySmsCode({ smsVerificationCode: Number(code) })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        if (e.message.includes("423")) {
          setFormState(FORM_STATES.TOO_MANY_ATTEMPTS_ERROR);
        } else {
          setFormState(FORM_STATES.WRONG_CODE_ERROR);
        }
      });
  };

  const removePhoneNumberAndReload = () => {
    removePhoneNumber()
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  const handleResendSmsVerificationCode = () => {
    setResendCodeState(RESEND_CODE_STATES.SENDING);

    resendSmsVerificationCode()
      .then(() => {
        setResendCodeState(RESEND_CODE_STATES.SUCCESS);
      })
      .catch((err) => {
        setResendCodeState(RESEND_CODE_STATES.ERROR);
      });
  };

  return (
    <div>
      <Icon style={{ fontSize: "50px", color: "#1890ff" }} type="message" />
      <h2>
        We sent a verification code to your phone (
        {formatPhoneNumber(userInfo.phone)})
      </h2>
      <h3 style={{ marginBottom: "20px" }}>
        Enter the 4-digit verification code to confirm your enrollment
      </h3>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div
          style={{
            ...(formState === FORM_STATES.ERROR && {
              border: "2px red solid",
              borderRadius: "8px",
            }),
          }}
        >
          <ReactCodeInput
            type="number"
            fields={4}
            onComplete={handleComplete}
            loading={formState === FORM_STATES.LOADING}
            disabled={formState === FORM_STATES.TOO_MANY_ATTEMPTS_ERROR}
          />
        </div>
      </div>
      {isPhoneOptedOut && (
        <Alert
          message={
            <strong>
              We couldn't text you because you previously texted STOP to opt out
              of text messages.
            </strong>
          }
          type="warning"
          description={
            <>
              <strong>
                <p>
                  Please text the word 'START' to {PHONE_NUMBERS_FOR_UI.TELNYX}{" "}
                  to opt in again.
                </p>
              </strong>
              <p>
                Once you have opted back in, click the 'Resend code' button
                below.
              </p>
            </>
          }
          showIcon
          style={{ margin: "16px 0" }}
        />
      )}
      <div>
        {formState === FORM_STATES.WRONG_CODE_ERROR && (
          <h4 style={{ paddingTop: "10px", color: "red" }}>
            Incorrect code. Please try again.
          </h4>
        )}
        {formState === FORM_STATES.TOO_MANY_ATTEMPTS_ERROR && (
          <h4 style={{ paddingTop: "10px", color: "red" }}>
            Too many attempts. Please contact customer support.
          </h4>
        )}
      </div>

      <div>
        <h3 style={{ marginTop: "50px" }}>Didn't receive the code?</h3>
        {resendCodeState === RESEND_CODE_STATES.ERROR && (
          <p>
            There was an issue sending the code to this number. Please reach out
            to support for help.
          </p>
        )}
        {resendCodeState === RESEND_CODE_STATES.SUCCESS && (
          <p>We've resent the code to this number.</p>
        )}

        {resendCodeState === RESEND_CODE_STATES.ALREADY_SENT && (
          <p>Contact customer support and we'll help you resend the code.</p>
        )}

        {(resendCodeState === RESEND_CODE_STATES.INITIAL ||
          resendCodeState === RESEND_CODE_STATES.SENDING) && (
          <Button
            onClick={handleResendSmsVerificationCode}
            loading={resendCodeState === RESEND_CODE_STATES.SENDING}
          >
            {resendCodeState === RESEND_CODE_STATES.SENDING
              ? "Sending"
              : "Resend code"}
          </Button>
        )}

        <div>
          {" "}
          <h3 style={{ marginTop: "30px" }}>Need to change your number?</h3>
          {userInfo.phoneNumberChangeCount >= MAX_PHONE_NUMBER_CHANGES ? (
            <p style={{ marginTop: "10px" }}>
              Contact support and we'll help you out.
            </p>
          ) : (
            <Button type="link" onClick={removePhoneNumberAndReload}>
              Change your phone number
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmsVerifyForm;
