const LOCAL_PORT = window.location.origin.includes(5001) ? 5001 : 3000;
const API_PORT = 5001;

export const isLocal = () => window.location.host.includes("localhost");

const isDeployedQa = () =>
  window.location.host.includes("qa-globalentrysentry") ||
  window.location.host.includes("qa.appointmentscanner");

const shouldUseLocalProd = () => localStorage.getItem("LOCAL_PROD");

export const isQA = () =>
  isDeployedQa() || (isLocal() && !shouldUseLocalProd());

const getApiDomain = () => {
  if (isLocal()) {
    if (shouldUseLocalProd()) {
      console.log("local PROD endpoint call");
      return `http://localhost:${API_PORT}/globalentrysentry/us-central1`;
    } else {
      console.log("local QA endpoint call");
      return `http://localhost:${API_PORT}/qa-globalentrysentry/us-central1`;
    }
  } else {
    if (isDeployedQa()) {
      console.log("deployed QA endpoint call");
      return "https://us-central1-qa-globalentrysentry.cloudfunctions.net";
    } else {
      return "https://us-central1-globalentrysentry.cloudfunctions.net";
    }
  }
};

export const getAppDomain = () => getApiDomain() + "/app";

export const getPublicDomain = () => getApiDomain() + "/public";

export const getPasswordLessSignInLink = () => {
  if (isLocal()) {
    return `http://localhost:${LOCAL_PORT}/signup`;
  } else {
    if (isDeployedQa()) {
      return "https://app.qa.appointmentscanner.com/signup";
    } else {
      return "https://app.appointmentscanner.com/signup";
    }
  }
};
