export const selfReportedReferralSourceNameMap = {
  SEARCH_ENGINE: "Search engine (Google, Bing, etc)",
  WORD_OF_MOUTH: "Recommended by friends, family or colleagues",
  ADS: "Saw an ad",
  BLOG: "Website / Blog",
  REDDIT: "Reddit",
  INSTAGRAM: "Instagram",
  TWITTER: "Twitter",
  FACEBOOK: "Facebook",
  TIKTOK: "TikTok",
  PODCAST: "Podcast",
  OTHER: "Other (enter your own)",
};
