import * as firebase from "firebase/app";
import { getAppDomain, getPublicDomain } from "./utils/domainUtils";
import "whatwg-fetch";

function handleErrorsAndGetJson(response) {
  if (!response.ok) {
    throw Error(response.status + " " + response.statusText);
  }
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    return response.json();
  }
  return response.body;
}

const unAuthenticatedRequest = (method, url, body) => {
  var request = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    ...(method === "POST" && { body: JSON.stringify(body) }),
  };

  return window.fetch(url, request).then(handleErrorsAndGetJson);
};

const authenticatedRequest = (method, url, body) => {
  if (!firebase.auth().currentUser) {
    // throw new Error("Not authenticated. Make sure you're signed in!");
  }

  // Get the Firebase auth token to authenticate the request
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(function (token) {
      var request = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        ...(method === "POST" && { body: JSON.stringify(body) }),
      };

      //   if (method === "POST") {
      //     request.contentType = "application/json";
      //     request.data = JSON.stringify(body);
      //   }
      // console.log("Making authenticated request:", method, url);
      return window.fetch(url, request).then(handleErrorsAndGetJson);
    });
};

export const placeOrder = (data) =>
  authenticatedRequest("POST", `${getAppDomain()}/order`, data);

export const setPreferences = (data) =>
  authenticatedRequest("POST", `${getAppDomain()}/preferences`, data);

export const verifyPromo = (data) =>
  authenticatedRequest("POST", `${getAppDomain()}/verify-promo`, data);

export const verifySmsCode = (data) =>
  authenticatedRequest("POST", `${getAppDomain()}/verify-sms-code`, data);

export const getUserInfo = () =>
  authenticatedRequest("GET", `${getAppDomain()}/user-info`);

export const setPaymentPending = () =>
  authenticatedRequest("POST", `${getAppDomain()}/payment-pending`);

export const clearRenewalPaymentPending = () =>
  authenticatedRequest(
    "POST",
    `${getAppDomain()}/clear-renewal-payment-pending`
  );

export const setRenewalPaymentPending = () =>
  authenticatedRequest("POST", `${getAppDomain()}/set-renewal-payment-pending`);

export const removePhoneNumber = () =>
  authenticatedRequest("POST", `${getAppDomain()}/remove-phone`);

export const resendSmsVerificationCode = () =>
  authenticatedRequest("POST", `${getAppDomain()}/resend-sms-code`);

export const submitFeedback = (data) =>
  authenticatedRequest("POST", `${getAppDomain()}/feedback`, data);

export const getLocationMap = () =>
  unAuthenticatedRequest("GET", `${getPublicDomain()}/location-map`);

export const getPhoneOptInState = () =>
  authenticatedRequest("POST", `${getAppDomain()}/phone-opt-status`);

export const getMissingCenters = () =>
  authenticatedRequest("GET", `${getAppDomain()}/missing-centers`);

// request
//   .field("name", nameValue)
//   .field("token", JSON.stringify(token))
//   .end((err, res) => {
//     if (err) console.log(err);
//     console.log("File uploaded!");
//     console.log("res", res);
//   });
