import React, { useCallback, useState, useEffect, Fragment } from "react";
import {
  Button,
  Input,
  Card,
  Checkbox,
  DatePicker,
  Select,
  Tooltip,
  Switch,
  message,
  Icon,
  Form,
} from "antd";
import moment from "moment";
import { without } from "underscore";
import { getDayNameFromNumber } from "./utils/dateUtils";

const dayNames = [0, 1, 2, 3, 4, 5, 6].map(getDayNameFromNumber);

const ExcludedDays = ({ excludedDays, onChange, disabled }) => {
  const handleChange = (dayNumber) => {
    if (excludedDays.includes(dayNumber)) {
      onChange(without(excludedDays, dayNumber));
    } else {
      onChange(excludedDays.concat(dayNumber));
    }
  };
  return (
    <Fragment>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div>
          {dayNames.map((dayName, i) => (
            <div
              key={i}
              style={{ display: "inline-block", textAlign: "center" }}
            >
              <p style={{ marginBottom: "5px" }}>{dayName}</p>
              <Button
                // type={excludedDays.includes(i) ? "secondary" : "primary"}
                key={i}
                onClick={() => handleChange(i)}
                style={{
                  marginRight: "2px",
                  width: "60px",
                  color: "white",
                  backgroundColor: excludedDays.includes(i)
                    ? "#DC143C"
                    : "#00FA9A",
                }}
                disabled={disabled}
              >
                {excludedDays.includes(i) ? (
                  <Icon type="close" />
                ) : (
                  <Icon type="check" />
                )}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          backgroundColor: "rgb(237,	238, 242)",
          borderRadius: "4px",
          padding: "5px 10px",
        }}
      >
        <Button
          size="small"
          style={{
            marginRight: "2px",
            width: "60px",
            color: "white",
            backgroundColor: "#DC143C",
          }}
        >
          <Icon type="close" />
        </Button>
        = Not available
        <Button
          size="small"
          style={{
            marginRight: "2px",
            marginLeft: "5px",
            width: "60px",
            color: "white",
            backgroundColor: "#00FA9A",
          }}
        >
          <Icon type="check" />
        </Button>
        = Available
      </div>
    </Fragment>
  );
};

export default ExcludedDays;
