import React, { Component, useState, Fragment } from "react";
import { Drawer, Button, Menu, Icon } from "antd";
import * as firebase from "firebase/app";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const Navbar = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("PREVIOUSLY_SIGNED_IN") || firebase.auth().currentUser
  );

  const RightMenu = ({ mode = "horizontal", style, isMobile }) => (
    <Menu mode={mode} style={style}>
      <Menu.Item key="mail" style={style}>
        {isAuthenticated ? (
          <a onClick={handleSignOutClick}>Sign out</a>
        ) : (
          <a href="/dashboard"> Sign in</a>
        )}
      </Menu.Item>
      {!isAuthenticated && (
        <Menu.Item key="app" style={style}>
          <a href="/">Sign up</a>
        </Menu.Item>
      )}
      {isMobile && [
        <Menu.Item key="terms" style={style}>
          <a target="_blank" href="https://appointmentscanner.com/terms.html">
            Terms of service
          </a>
        </Menu.Item>,
        <Menu.Item key="privacy" style={style}>
          <a target="_blank" href="https://appointmentscanner.com/privacy.html">
            Privacy
          </a>
        </Menu.Item>,
        <Menu.Item key="refunds" style={style}>
          <a target="_blank" href="https://appointmentscanner.com/refunds.html">
            Refunds
          </a>
        </Menu.Item>,
        <Menu.Item key="contact" style={style}>
          <a href="mailto:support@appointmentscanner.com">Contact</a>
        </Menu.Item>,
      ]}
    </Menu>
  );

  const LeftMenu = () => null;
  // <Menu mode="horizontal">
  //   <Menu.Item key="mail">
  //     <a href="">Home</a>
  //   </Menu.Item>
  //   <SubMenu title={<span>Blogs</span>}>
  //     <MenuItemGroup title="Item 1">
  //       <Menu.Item key="setting:1">Option 1</Menu.Item>
  //       <Menu.Item key="setting:2">Option 2</Menu.Item>
  //     </MenuItemGroup>
  //     <MenuItemGroup title="Item 2">
  //       <Menu.Item key="setting:3">Option 3</Menu.Item>
  //       <Menu.Item key="setting:4">Option 4</Menu.Item>
  //     </MenuItemGroup>
  //   </SubMenu>
  //   <Menu.Item key="alipay">
  //     <a href="">Contact Us</a>
  //   </Menu.Item>
  // </Menu>

  firebase.auth().onAuthStateChanged(function (user) {
    if (user && !isAuthenticated) {
      setIsAuthenticated(true);
    }
  });

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const handleSignOutClick = () => {
    firebase.auth().signOut();
    window.location.reload();
  };

  return (
    <nav className="menuBar" style={{ overflow: "hidden" }}>
      <div className="logo">
        <a href="">
          <img src="logo.svg" style={{ marginLeft: "10px" }} height="40px" />{" "}
        </a>
      </div>
      <div className="menuCon" style={{ height: "100%" }}>
        <div className="leftMenu">
          <LeftMenu />
        </div>
        <div className="rightMenu">
          <RightMenu />
        </div>
        <Button className="barsMenu" type="secondary" onClick={showDrawer}>
          <Icon type="menu" style={{ color: "gray", fontSize: "20px" }} />
        </Button>
        <Drawer
          title={
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a href="">
                <img src="logo.svg" height="40px" />
              </a>
              <Icon
                type="close"
                onClick={onClose}
                style={{ color: "gray", float: "right", fontSize: "24px" }}
              />
            </div>
          }
          placement="right"
          closable={false}
          onClose={onClose}
          visible={isDrawerVisible}
          width="100vw"
        >
          <LeftMenu />
          <RightMenu
            mode="vertical"
            style={{ fontSize: "16pt", textAlign: "center" }}
            isMobile={true}
          />
        </Drawer>
      </div>
    </nav>
  );
};
export default Navbar;
