import { Alert, Button, Form, Radio } from "antd";
import React, { useState } from "react";
import PaddleCheckoutForm from "./PaddleCheckoutForm";
import PaymentPendingScreen from "./PaymentPendingScreen";
import moment from "moment";
import { getIsMostRecentPaymentDateLessTwentyMinutesAgo } from "./utils/paymentUtils";
import { PROGRAM_TYPES } from "./constants/programTypes";
import { setSelectedProgramDuringQuestionnaire } from "./utils/programSelectionUtils";

const FORM_STATES = {
  INITIAL: "INITIAL",
  MAYBE_NOT_ELIGIBLE_NEXUS: "MAYBE_NOT_ELIGIBLE_NEXUS",
  ELIGIBLE_1: "ELIGIBLE_1",
  ELIGIBLE_2: "ELIGIBLE_2",
  ELIGIBLE_3: "ELIGIBLE_3",
  ELIGIBLE_4: "ELIGIBLE_4",
  SHOW_CHECKOUT: "SHOW_CHECKOUT",
  UNSURE_CONDITIONAL_APPROVAL: "UNSURE_CONDITIONAL_APPROVAL",
  UNSURE_TIME_COMMITMENT: "UNSURE_TIME_COMMITMENT",
};

const CheckoutQuestionaireForm = ({ onSuccess, userInfo, fetchUserInfo }) => {
  const [formState, setFormState] = useState(FORM_STATES.INITIAL);
  const isPaymentPending =
    userInfo && userInfo.isPaymentPending && !userInfo.paymentDate;
  const isMostRecentPaymentDateLessTwentyMinutesAgo =
    getIsMostRecentPaymentDateLessTwentyMinutesAgo();

  console.log(
    "isMostRecentPaymentDateLessTwentyMinutesAgo in checkout questionaire",
    isMostRecentPaymentDateLessTwentyMinutesAgo
  );
  const [hasPaidLocally, setHasPaidLocally] = useState(false);

  if (
    isPaymentPending ||
    hasPaidLocally ||
    isMostRecentPaymentDateLessTwentyMinutesAgo
  ) {
    console.log("questionaire - rendering payment pending screen");

    return <PaymentPendingScreen fetchUserInfo={fetchUserInfo} />;
  } else if (formState === FORM_STATES.SHOW_CHECKOUT) {
    console.log("questionaire - rendering paddle checkout");

    return (
      <PaddleCheckoutForm
        // onSuccess={onSuccess}
        userInfo={userInfo}
        fetchUserInfo={fetchUserInfo}
        setHasPaidLocally={setHasPaidLocally}
      />
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form>
          <h2>Welcome! Now, some quick details:</h2>
          <Form.Item
            style={
              formState === FORM_STATES.INITIAL ||
              formState == FORM_STATES.MAYBE_NOT_ELIGIBLE_NEXUS
                ? {
                    borderLeft: "4px #1890ff solid",
                    paddingLeft: "10px",
                  }
                : { opacity: 0.5, paddingLeft: "14px" }
            }
          >
            <h4>Which program?</h4>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              onChange={(event) => {
                setFormState(FORM_STATES.ELIGIBLE_1);

                // if (event.target.value === PROGRAM_TYPES.NEXUS) {
                //   setFormState(FORM_STATES.MAYBE_NOT_ELIGIBLE_NEXUS);
                // } else {
                //   setFormState(FORM_STATES.ELIGIBLE_1);
                // }
                setSelectedProgramDuringQuestionnaire(event.target.value);
              }}
            >
              <Radio.Button value={PROGRAM_TYPES.GLOBAL_ENTRY}>
                Global Entry
              </Radio.Button>
              <Radio.Button value={PROGRAM_TYPES.SENTRI}>SENTRI</Radio.Button>
              <Radio.Button value={PROGRAM_TYPES.NEXUS}>NEXUS</Radio.Button>
            </Radio.Group>
            {/* {formState === FORM_STATES.MAYBE_NOT_ELIGIBLE_NEXUS && (
              <Alert
                style={{ marginTop: "10px", maxWidth: "500px" }}
                type="info"
                message="All Canadian NEXUS centers remain closed. US centers are open."
                description={
                  <div>
                    <p>
                      If you want a NEXUS interview in the USA, you can continue
                      signing up for Appointment Scanner.
                    </p>
                    <p>
                      If you need a NEXUS interview in Canada, we recommend
                      waiting to sign up for Appointment Scanner until NEXUS
                      interviews resume at Canadian enrollment centers.
                    </p>

                    <Button
                      onClick={() => {
                        setFormState(FORM_STATES.ELIGIBLE_1);
                      }}
                      style={{ marginBottom: "10px" }}
                    >
                      I understand, continue
                    </Button>

                    <p>
                      You can read the{" "}
                      <a
                        href="https://www.cbsa-asfc.gc.ca/prog/nexus/location-eng.html"
                        target="_blank"
                      >
                        press release on the CBSA site
                      </a>{" "}
                      for more information.
                    </p>
                    <p>
                      Still have questions? Send an email to
                      support@appointmentscanner.com or start a chat.
                    </p>
                  </div>
                }
              />
            )} */}
          </Form.Item>

          <Form.Item
            style={
              formState === FORM_STATES.ELIGIBLE_1 ||
              formState == FORM_STATES.UNSURE_CONDITIONAL_APPROVAL
                ? {
                    borderLeft: "4px #1890ff solid",
                    paddingLeft: "10px",
                  }
                : { opacity: 0.5, paddingLeft: "14px" }
            }
          >
            <h4>
              Has your Global Entry / SENTRI / NEXUS application been
              conditionally approved?
            </h4>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              onChange={(event) => {
                if (event.target.value === "yes") {
                  setFormState(FORM_STATES.ELIGIBLE_2);
                } else {
                  setFormState(FORM_STATES.UNSURE_CONDITIONAL_APPROVAL);
                }
              }}
            >
              <Radio.Button value="yes">Yes!</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
              <Radio.Button value="unsure">Not sure</Radio.Button>
            </Radio.Group>
            {formState === FORM_STATES.UNSURE_CONDITIONAL_APPROVAL && (
              <Alert
                style={{ marginTop: "10px", maxWidth: "500px" }}
                type="info"
                message="To use Appointment Scanner you'll need to wait until you receive notice that your Global Entry application has been conditionally approved."
                description={
                  <div>
                    <p>
                      When you submit your Global Entry / SENTRI / NEXUS
                      application it will be reviewed (usually for a few
                      months). Then you will receive notice that it has been
                      conditionally approved and that you must now schedule an
                      interview in the next 365 days. If you have not yet
                      received notice that your application has been
                      conditionally approved you will not be able to schedule an
                      interview yet, or get any value out of Appointment
                      Scanner.
                    </p>
                    <p>
                      If you're applying for a renewal, you may or may not need
                      an interview, so make sure to wait until you you receive
                      notice that you can schedule an interview.
                    </p>
                    <p>
                      You can double check your application or renewal status by
                      logging into the{" "}
                      <a href="https://ttp.cbp.dhs.gov/" target="_blank">
                        Trusted Traveler Program website.
                      </a>
                    </p>
                  </div>
                }
              />
            )}
          </Form.Item>
          <Form.Item
            style={
              formState === FORM_STATES.ELIGIBLE_2
                ? {
                    borderLeft: "4px #1890ff solid",
                    paddingLeft: "10px",
                  }
                : { opacity: 0.5, paddingLeft: "14px" }
            }
          >
            <h4>Have you already booked an interview appointment?</h4>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              onChange={(event) => {
                setFormState(FORM_STATES.ELIGIBLE_3);
              }}
            >
              <Radio.Button value="yes">
                Yes, but I'd like to find a sooner one
              </Radio.Button>
              <Radio.Button value="no">
                No, my location has no suitable appointments available
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            style={
              formState === FORM_STATES.ELIGIBLE_3 ||
              formState == FORM_STATES.UNSURE_TIME_COMMITMENT ||
              formState == FORM_STATES.ELIGIBLE_4
                ? {
                    borderLeft: "4px #1890ff solid",
                    paddingLeft: "10px",
                  }
                : { opacity: 0.5, paddingLeft: "14px" }
            }
          >
            <h4>
              Will you generally be able to react quickly to alerts about
              appointment openings?
            </h4>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              onChange={(event) => {
                if (event.target.value === "yes") {
                  setFormState(FORM_STATES.ELIGIBLE_4);
                } else {
                  setFormState(FORM_STATES.UNSURE_TIME_COMMITMENT);
                }
              }}
            >
              <Radio.Button value="yes">Yes!</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
              <Radio.Button value="unsure">Not sure</Radio.Button>
            </Radio.Group>
            {formState === FORM_STATES.UNSURE_TIME_COMMITMENT && (
              <Alert
                style={{ marginTop: "10px", maxWidth: "500px" }}
                type="info"
                message="Appointment Scanner works best when you can respond quickly to alerts."
                description={
                  <p>
                    Many last minute appointments get taken within a few minutes
                    after they open up. Appointment Scanner helps you know
                    instantly when these appointments open up, but it still
                    requires you to book the appointment yourself. So you'll
                    have the best luck with Appointment Scanner if you can
                    generally respond to text message or email alerts within a
                    couple minutes. It's OK if you're not around all hours of
                    the day, but the quicker you are to respond to alerts, the
                    faster you'll be able to find an appointment.
                  </p>
                }
              />
            )}
            {formState === FORM_STATES.ELIGIBLE_4 && (
              <Alert
                style={{ marginTop: "10px", maxWidth: "500px" }}
                type="info"
                message="Great! Appointment Scanner is a good fit for you."
                description={
                  <div>
                    <p>Let's continue setting up your account.</p>
                    <Button
                      size="large"
                      onClick={() => {
                        setFormState(FORM_STATES.SHOW_CHECKOUT);
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                }
              />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default CheckoutQuestionaireForm;
