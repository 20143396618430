import { PROGRAM_TYPES } from "../constants/programTypes";

const SELECTED_PROGRAM_KEY = "appointment-scanner-selected-program";

export const setSelectedProgramDuringQuestionnaire = (value) => {
  window.localStorage.setItem(SELECTED_PROGRAM_KEY, value);
};

export const getSelectedProgramDuringQuestionnaire = () => {
  const storedValue = window.localStorage.getItem(SELECTED_PROGRAM_KEY);

  if (PROGRAM_TYPES[storedValue]) {
    return storedValue;
  } else {
    return null;
  }
};
